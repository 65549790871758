.list-item-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "list-item list-item list-item delete-list-item";
}

.list-item {
    grid-area: list-item;
    margin: 5px 10px;
    padding: 15px 15px;
    background-color: #ebf7fa;
    border-radius: 5px;
    font-family: var(--body, Circular_book);
    font-size: var(--fontsize-body);
}

.deleted-list-item {
    grid-area: list-item;
    margin: 5px 10px;
    padding: 15px 15px;
    background-color: rgb(251, 234, 234);
    border-radius: 5px;
    font-size: var(--fontsize-body);
    text-decoration: line-through;
}

.delete-list-item {
    grid-area: delete-list-item;
    margin: 5px 0px;
    border-radius: 5px;
    border: 0px;
    background-color: inherit;
    align-self: center;
}

.delete-icon{
    width: 30px;
    height: 30px;
}

.delete-icon:hover{
    background-color: #e9f1ff;
    border-radius: 5px;
    transform: scale(1.20);
    cursor: pointer;
}

.undo-icon{
    width: 30px;
    height: 30px;
}

.undo-icon:hover{
    background-color: #e9f1ff;
    border-radius: 5px;
    transform: scale(1.20);
    cursor: pointer;
}