.SupportItemPanel-Panel {
    min-width: 275px;
    max-width: 275px;
    max-height: 205px;
    min-height: 200px;
    background-color: rgb(252, 252, 252);
    padding: 15px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px 1px #D9D9D9;

    display: grid;
    grid-template-columns: 100px 150px;
    grid-template-rows: 100px 50px 50px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "Support-Item-Icon Support-Item-Title Support-Item-Title"
        "Support-Item-Description Support-Item-Description Support-Item-Description"
        "Support-Item-Label Support-Item-Label Support-Item-Label";
}

.SupportItemPanel-Panel:hover {
    background-color: #f8f9fb;
    transform: scale(1.05);
}


.Support-Item-Icon {
    grid-area: Support-Item-Icon;
    justify-self: center;
    align-self: center;
    padding: 5px;
}

.Support-Item-Title {
    grid-area: Support-Item-Title;
    align-self: center;
    font-family: var(--subheader);
    font-size: var(--fontsize-subheader);
    line-height: 1.6;
}

.Support-Item-Description {
    grid-area: Support-Item-Description;
    font-family: var(--body);
    padding: 3px;
    line-height: 1.5;
    color: #282E36;
    margin-left: 8px;
}

.Support-Item-Label {
    grid-area: Support-Item-Label;
    font-family: var(--body);
    font-size: var(--fontsize-label);
    padding: 5px 10px;
    line-height: 1.5;
    background-color: #DEF6FC;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    color: rgb(48, 59, 83);
    margin: 0px 20px;
}