.radio-buttons-container{
    margin-bottom: 15px;
}

.radio-button-container{
    flex-direction: row;
}

.radio-field-label{
    font-family: var(--body);
    font-size: var(--fontsize-body);
    margin: 0px 5px 0px 5px;
    position: relative;
    bottom: 5px;
}

.radio-button{
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
    margin-left: 5px;
}
