/* Parent container and contents*/
.Parent-Container-GettingStarted {
    margin-top: 2%;
    padding: 50px 50px;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.Content-Container-GettingStarted {
    display: grid;
    grid-template-areas: 'Icon-Content-GettingStarted Text-Content-GettingStarted';
    margin-bottom: 3%;
    grid-template-columns: 125px;
    line-height: 1.6;
}

.Icon-Content-GettingStarted {
    grid-area: Icon-Content-GettingStarted;
    align-self: center;
}

.Text-Content-GettingStarted {
    grid-area: Text-Content-GettingStarted;
}

.Text-Content-GettingStarted h3 {
    margin: 7px 0px;
}

/* Buttons */
.Text-Content-GettingStarted button {
    background-color: #18B4EA;
    border: none;
    border-radius: 3px;
    padding: 3px 8px;
    margin: 0px 10px;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
}

.Text-Content-GettingStarted button:hover{
    text-decoration: underline;
}
 
.Link-Content-GettingStarted {
    color: inherit;
    text-decoration: inherit;
}

/* Modal Image */
.modal-lucky-image-gettingstarted {
    max-width: 70%;
    max-height: 70%;
    align-self: center;
}

.modal-lucky-image-gettingstarted:hover {
    transition: 0.2s;
    -webkit-animation: spin 1s linear 1;
    -moz-animation: spin 1s linear 1;
    animation: spin 1s linear 1;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}