.table-response-container {
  background-color: white;
  border-radius: 2px;
  padding: 5px;
  margin: 0px;
  min-width: 685px;
  overflow: auto;
  text-align: center;
}

.table-response-text-container {
  background-color: white;
  border-radius: 2px;
  padding: 15px;
  text-align: left;
  min-width: 685px;
  overflow: auto;
}

.table-response-container-pending {
  background-color: white;
  border-radius: 2px;
  margin: 2% 0% 3% 0%;
  padding: 50px 15px;
  min-width: 685px;
  overflow: auto;
  text-align: center;
}


table.table-response {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  text-align: left;
}


table.table-response tr {
  border-bottom: 0.5px solid lightgray;
}

table.table-response tr td:first-child {
  padding-left: 50px;
}

table.table-response tr th:first-child {
  padding-left: 50px;
}

table.table-response th {
  padding: 30px 20px;
  font-weight: bold;
  font-size: var(--fontsize-body);
}

table.table-response td {
  padding: 20px;
  line-height: 1.7;
  word-wrap: break-word;
  font-size: var(--fontsize-body);
}

.table-response-link-parent {
  text-decoration: none;
}

.table-response-link {
  display: flex;
  align-content: center;
}

.table-response-link-img {
  cursor: pointer;
  transition: transform .2s;
}

.table-response-link-img:hover {
  transform: scale(1.3);
}

.table-response-link-tooltip {
  visibility: hidden;
  /* text-align: center; */
  font-family: var(--body);
  width: fit-content;
  background-color: var(--tooltip-background-colour, #343434);
  color: #FFFFFF;
  font-size: var(--fontsize-label);
  line-height: 1.4;
  border-radius: 3px;
  padding: 5px 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  transform: translateX(10px);
}

.table-response-link-img:hover ~ .table-response-link-tooltip {
  visibility: visible;
  text-decoration: none;
}

.icon {
  word-wrap: break-word;
  font-size: var(--fontsize-body);
  text-align: center;
  border-radius: 2px;
  padding: 5px;
  min-width: 25px;
  min-height: 25px;
}

.icon:hover {
  background-color: #e9f1ff;
  padding: 5px;
  cursor: pointer;
}


.table-message {
  line-height: 1.6;
  font-size: var(--fontsize-body);
  text-align: left;
}

.table-stop-button-container {
  margin-top: 80px;
}

.table-stop-button {
  padding: 10px 15px;
  margin: 0px 10px;
  font-size: var(--fontsize-body);
  font-family: var(--body);
  border: none;
  box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
  background-color: var(--button-cancel-colour, #fc3c78);
  color: white;
  border-radius: 3px;
}

.table-stop-button:hover {
  background-color: var(--button-cancel-colour-hover, #f21258);
  color: white;
}

.try-again-button {
  background-color: var(--button-action-colour, #0C6CDE);
  border: none;
  color: white;
  padding: 15px 30px;
  margin: 10px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: var(--fontsize-button);
  text-align: center;
  font-family: var(--body, Circular-body);
}

.try-again-button:hover {
  background-color: var(--button-action-colour-hover, #00499F);
}


.loading {
  position: relative;
  width: 180px;
  height: 180px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: hue-rotate 10s linear infinite both;
  animation: hue-rotate 10s linear infinite both;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 15px;
}

.loading-square {
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 55px;
  margin: 2px;
  border-radius: 2px;
  background: rgb(151, 211, 236);
  background-image: linear-gradient(40deg, rgb(0, 247, 255) 40%, #0c9 60%);
  background-image: -moz-linear-gradient(rgb(8, 249, 28), rgba(5, 244, 204, 0.142));
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  -webkit-animation: square-animation 10s ease-in-out infinite both;
  animation: square-animation 10s ease-in-out infinite both;
}
.loading-square:nth-of-type(0) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.loading-square:nth-of-type(1) {
  -webkit-animation-delay: -1.4285714286s;
  animation-delay: -1.4285714286s;
}
.loading-square:nth-of-type(2) {
  -webkit-animation-delay: -2.8571428571s;
  animation-delay: -2.8571428571s;
}
.loading-square:nth-of-type(3) {
  -webkit-animation-delay: -4.2857142857s;
  animation-delay: -4.2857142857s;
}
.loading-square:nth-of-type(4) {
  -webkit-animation-delay: -5.7142857143s;
  animation-delay: -5.7142857143s;
}
.loading-square:nth-of-type(5) {
  -webkit-animation-delay: -7.1428571429s;
  animation-delay: -7.1428571429s;
}
.loading-square:nth-of-type(6) {
  -webkit-animation-delay: -8.5714285714s;
  animation-delay: -8.5714285714s;
}
.loading-square:nth-of-type(7) {
  -webkit-animation-delay: -10s;
  animation-delay: -10s;
}


@-webkit-keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }
  10.5% {
    left: 0;
    top: 0;
  }
  12.5% {
    left: 64px;
    top: 0;
  }
  23% {
    left: 64px;
    top: 0;
  }
  25% {
    left: 128px;
    top: 0;
  }
  35.5% {
    left: 128px;
    top: 0;
  }
  37.5% {
    left: 128px;
    top: 64px;
  }
  48% {
    left: 128px;
    top: 64px;
  }
  50% {
    left: 64px;
    top: 64px;
  }
  60.5% {
    left: 64px;
    top: 64px;
  }
  62.5% {
    left: 64px;
    top: 128px;
  }
  73% {
    left: 64px;
    top: 128px;
  }
  75% {
    left: 0;
    top: 128px;
  }
  85.5% {
    left: 0;
    top: 128px;
  }
  87.5% {
    left: 0;
    top: 64px;
  }
  98% {
    left: 0;
    top: 64px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }
  10.5% {
    left: 0;
    top: 0;
  }
  12.5% {
    left: 64px;
    top: 0;
  }
  23% {
    left: 64px;
    top: 0;
  }
  25% {
    left: 128px;
    top: 0;
  }
  35.5% {
    left: 128px;
    top: 0;
  }
  37.5% {
    left: 128px;
    top: 64px;
  }
  48% {
    left: 128px;
    top: 64px;
  }
  50% {
    left: 64px;
    top: 64px;
  }
  60.5% {
    left: 64px;
    top: 64px;
  }
  62.5% {
    left: 64px;
    top: 128px;
  }
  73% {
    left: 64px;
    top: 128px;
  }
  75% {
    left: 0;
    top: 128px;
  }
  85.5% {
    left: 0;
    top: 128px;
  }
  87.5% {
    left: 0;
    top: 64px;
  }
  98% {
    left: 0;
    top: 64px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes hue-rotate {
  0% {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}
@keyframes hue-rotate {
  0% {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}