/* Project Ownership Transfer PTE Form */

.AcceptOwnershipTransfer-Form-Table {
    margin: 2% 0%;
}

.AcceptOwnershipTransfer-Form-Table th {
    border-bottom: 0.5px solid lightgray;
    padding-bottom: 10px;
}

.AcceptOwnershipTransferForm-td {
    padding: 20px 5px 0px 5px
}

/* Child Component - 'AcceptOwnershipTransferForm' */
.AcceptOwnershipTransferForm-Input {
    display: flex;
    flex-direction: column;
    padding: 20px 5px 0px 5px
}

.AcceptOwnershipTransferForm-response-label {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    font-style: italic;
    padding: 0px 5px 0px 5px;
    line-height: 1.6;
}

.AcceptOwnershipTransferForm-error-label {
    padding: 0px 5px 0px 5px;
}