/* ---------------------------- */
/*  Groups Parent Container     */
/* ---------------------------- */

.Groups-Page-Container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

/* ---------------------------- */
/*  Project Operators Table     */
/* ---------------------------- */

.Project-Operators-Header{
    display: flex;
    flex-direction: column;
    gap: 0px;
}

/* ---------------------------- */
/*  My Groups Table             */
/* ---------------------------- */

.MyGroups-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.product-parent-container{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    min-width: 60%;
    max-width: 100%;
    height: 100%;
}

.groups-create-button-container{
    display: flex;
    justify-content: flex-end;
}

.create-group-button{
    padding: 10px 15px;
    margin: 0px 10px;
font-size: var(--fontsize-body);
    font-family: var(--body);
    border: none;
    box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
    background-color: var(--button-action-colour, #0C6CDE);
    color: white;
    border-radius: 3px;
    grid-column: 2;
    grid-row: 1;
    width: 150px;
    height: fit-content;
}

.create-group-button:hover{
    background-color: var(--button-action-colour-hover,#00499F);
    color: white;
}

.create-group-button:disabled{
    background-color: rgb(233, 233, 233);
    color: rgb(199, 198, 198);
    box-shadow: none;
    cursor: not-allowed;
}

.icon-edit {
    word-wrap: break-word;
    font-size: var(--fontsize-body);
    text-align: center;
    border-radius: 2px;
    padding: 5px;
    min-width: 25px;
    min-height: 25px;
    cursor: pointer;
    margin-left: 10px;
}
  
.icon-edit:hover {
    background-color: #e9f1ff;
    padding: 5px;
}

.icon-edit-disabled {
    word-wrap: break-word;
    font-size: var(--fontsize-body);
    text-align: center;
    border-radius: 2px;
    padding: 5px;
    min-width: 40px;
    min-height: 40px;
    filter: grayscale(1);
}

/* The tooltip for the above disabled edit icon */
.icon-edit-disabled-tooltip{
    display: none;
    text-align: center;
    background-color: #343434;
    width: 350px;
    height: fit-content;
    color: #ffffff;
    border-radius: 5px;
    position: absolute;
    bottom: 45px;
    left: 25px;
    padding: 10px;
}
.icon-edit-disabled:hover > .icon-edit-disabled-tooltip{
    display: block;
}

.tooltip-link{
    color: rgb(139, 199, 255);
    font-weight: bold;
}

/* ------------------------------------------------------------------------------------------ */
/*   OLD CSS FOR CREATE GROUP FORM                                                            */
/* ------------------------------------------------------------------------------------------ */

/* ========================== */
/*  Form Container            */
/* ========================== */

.form-parent-container{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    min-width: 60%;
    max-width: 100%;
    height: 100%;
}

.form-content-pane{
    margin: 0% 2%;
}

/* ========================== */
/*  Request Form              */
/* ========================== */

.request-form{
    display: flex;
    flex-flow: column;
    border-radius: 2px;
    padding: 2% 3%;
    margin: 2% 20% 2% 0%;
    background-color: rgb(250, 250, 250);
    border-bottom: 1px solid rgb(208, 208, 208);
}

/* ========================== */
/*  Add Account Button        */
/* ========================== */

.add-account-button{
    background-color: var(--button-action-colour, #0C6CDE);
    border: none;
    color: white;
    padding: 5px;
    margin: 10px 0px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: var(--fontsize-body);
    text-align: center;
    width: 60px;
    font-family: var(--body);
}

.add-account-button:hover{
    background-color: var(--button-action-colour-hover,#00499F);
}

/* ------------------------------------------------------------------------------------------ */
/*   OLD CSS FOR MODIFY GROUP FORM                                                            */
/* ------------------------------------------------------------------------------------------ */

/* ========================== */
/*  Form Container            */
/* ========================== */

.form-parent-container{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    min-width: 60%;
    max-width: 100%;
    height: 100%;
}

.form-content-pane{
    margin: 0% 2%;
}

/* ========================== */
/*  Request Form              */
/* ========================== */

.request-form{
    display: flex;
    flex-flow: column;
    border-radius: 2px;
    padding: 2% 3%;
    margin: 2% 20% 2% 0%;
    background-color: rgb(250, 250, 250);
    border-bottom: 1px solid rgb(208, 208, 208);
}

/* ========================== */
/*  Add Account Button        */
/* ========================== */

.add-account-button{
    background-color: var(--button-action-colour, #0C6CDE);
    border: none;
    color: white;
    padding: 5px;
    margin: 10px 0px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
font-size: var(--fontsize-body);
    text-align: center;
    width: 60px;
    font-family: var(--body);
}

.add-account-button:hover{
    background-color: var(--button-action-colour-hover,#00499F);
}
