/* ================================ */
/*  View - Project Settings Tiles   */
/* ================================ */

.Project-Settings-Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px 20px;
    padding: 2%;
}

.Project-Settings-Tile-Container {
    position: relative;
}

.Project-Settings-Tile {
    display: grid;
    grid-template-columns: 250px;
    grid-template-rows: 90px 60px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "Project-Setting-Logo"
        "Project-Setting-Title";
    background-color: #FFFFFF;
    color: #0C6CDE;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 10px;
    padding: 10px;
    align-content: center;
    justify-content: center;
    cursor: pointer;
}

.Project-Settings-Tile:hover {
    background-color: #DEF6FC;
}

.Project-Settings-Tile:disabled {
    background-color: rgb(250, 250, 250);
    color: rgb(172, 172, 172);
    box-shadow: none;
    cursor: not-allowed;
    border: 0.5px solid rgb(228, 227, 227);
}

.Project-Setting-Logo {
    grid-area: Project-Setting-Logo;
    align-self: end;
    justify-self: center;
}

.Project-Setting-Title {
    grid-area: Project-Setting-Title;
    font-size: var(--fontsize-subheader);
    font-weight: normal;
    align-self: center;
    justify-self: center;
    text-align: center;
    line-height: 1.6;
    font-family: var(--body);
}

.Project-Settings-Tile-Container .Project-Settings-Tile-Tooltip {
    visibility: hidden;
    text-align: left;
    font-family: var(--body);
    width: fit-content;
    min-width: 300px;
    background-color: var(--tooltip-background-colour, #343434);
    color: #FFFFFF;
    font-size: var(--fontsize-body);
    line-height: 1.4;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    z-index: 100;

    /* Position the tooltip */
    position: absolute;
}

.Project-Settings-Tile-Container:hover .Project-Settings-Tile-Tooltip {
    visibility: visible;
}

/* ======================= */
/* Edit Budget & Cost      */
/* ======================= */

.Project-Edit-Budget-response-label {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    font-style: italic;
    margin: 0px 0px 0px 0px;
    line-height: 1.6;
}

/* ================================ */
/*  View - Modify Operators         */
/* ================================ */

/* -------------------------- */
/*  Form Container            */
/* -------------------------- */

.form-parent-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    min-width: 60%;
    max-width: 100%;
    height: 100%;
}

.form-content-pane {
    margin: 0% 2%;

}

/* ------------------------------- */
/*  What is a Project Operator?    */
/* ------------------------------- */

.project-modal-content-open-button {
    display: inline;
    color: var(--button-action-colour, #0C6CDE);
    cursor: pointer;
    text-decoration: none;
}

/* -------------------------- */
/*  Request Form              */
/* -------------------------- */

.request-form {
    display: flex;
    flex-flow: column;
    border-radius: 2px;
    padding: 2% 3%;
    margin: 2% 20% 2% 0%;
    background-color: rgb(250, 250, 250);
    border-bottom: 1px solid rgb(208, 208, 208);

}

/* -------------------------- */
/*  Add Account Button        */
/* -------------------------- */

.add-account-button {
    background-color: var(--button-action-colour, #0C6CDE);
    border: none;
    color: white;
    padding: 5px;
    margin: 10px 0px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: var(--fontsize-body);
    text-align: center;
    width: 60px;
    font-family: var(--body);
}

.add-account-button:hover {
    background-color: var(--button-action-colour-hover, #00499F);
}



/* ===================================== */
/*  View - Attach Project to Portfolio   */
/* ===================================== */


.Select-Portfolio {
    display: flex;
    flex-direction: row;
    gap: 0px 15px;

}

/* ===================================== */
/*  View - Project Delete                */
/* ===================================== */

.Delete-Form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Heading-Section {
    display: flex;
    align-items: center;
    letter-spacing: 0.15rem;
    font-size: 2.6rem;
}

.Button-Controls {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}