.datalistfield {
    font-family: var(--body);
    width: 30%;
    padding: 12px 20px;
    margin: 0px 0px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: black;
    font-size: var(--fontsize-body);
}

.datalistfield::placeholder {
    color: var(--inputfield-placeholder-color);
}

.datalistfield:hover {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.datalistfield:focus {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.datalistfield-fullsize {
    font-family: var(--body);
    width: 100%;
    padding: 12px 20px;
    margin: 0px 0px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: black;
    font-size: var(--fontsize-body);
}

.datalistfield-fullsize::placeholder {
    color: var(--inputfield-placeholder-color);
}

.datalistfield-fullsize:hover {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.datalistfield-fullsize:focus {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}