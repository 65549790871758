.textarea {
    font-family: var(--body);
    width: 100%;
    padding: 12px 20px;
    margin: 0px 0px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
    overflow: auto;
    font-size: var(--fontsize-body);
}

.textarea:hover {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.textarea::placeholder {
    color: var(--inputfield-placeholder-color);
}

.textarea:focus {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}