.disclaimer-parent-container{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    min-width: 60%;
    max-width: 100%;
    height: fit-content;
    background-color: white;
    padding: 10px 30px;
    border-radius: 5px;
    line-height: 1.7;
}

.disclaimer-title{
    font-size: var(--fontsize-subheader);
    font-weight: normal;
}