.form-parent-container{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    min-width: 60%;
    max-width: 100%;
    height: 100%;
}

.body1{
    padding: 1px;
    font-weight: lighter;
    font-family: var(--body);
}

.body2{
    padding: 1px;
    font-weight: lighter;
    font-family: var(--body);
    padding-bottom: 30px;
}

.form-content-pane{
    margin: 0% 2%;
}


.request-form{
    display: flex;
    flex-flow: column;
    border-radius: 2px;
    padding: 2% 3%;
    margin: 2% 20% 2% 0%;
    background-color: rgb(250, 250, 250);
    border-bottom: 1px solid rgb(208, 208, 208);
}

.list-container{
    padding: 10px 0px;    
}

.submit-button{
    background-color: var(--button-action-colour, #0C6CDE);
    border: none;
    color: white;
    padding: 15px 30px;
    margin: 40px 0px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: var(--fontsize-button);
    font-weight: bold;
    text-align: center;
}

.submit-button:hover{
    background-color: var(--button-action-colour-hover, #00499F);
}

.submit-button:disabled{
    background-color: rgb(233, 233, 233);
    color: rgb(199, 198, 198);
    box-shadow: none;
    cursor: not-allowed;
}

.submit-button:disabled:hover{
    background-color: rgb(233, 233, 233);
    color: rgb(199, 198, 198);
    box-shadow: none;
    cursor: not-allowed;
}


.add-account-button{
    background-color: var(--button-action-colour, #0C6CDE);
    border: none;
    color: white;
    padding: 5px;
    margin: 10px 0px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: var(--fontsize-body);
    text-align: center;
    width: 60px;
    font-family: var(--body);
}

.add-account-button:hover{
    background-color: var(--button-action-colour-hover,#00499F);
}

table.managevpcaccess-success{
    table-layout: fixed;
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

table.managevpcaccess-success td{
    padding: 10px;
    border: 1px solid rgb(226, 226, 226);
}
