/* Background Colour Container */
.modal-template-background {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

/* Modal Container */
.modal-content-container {
    z-index: 2;
    width: 970px;
    position: absolute;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #282E36;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
}

/* Close Button */
.modal-content-close-button {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    color: white;
}

/* Heading & Body content  */
.modal-content-heading {
    margin-left: 10px;
    padding: 2%;
    color: white;
    font-size: var(--fontsize-subheader);
}
.modal-content-body {
    padding: 10px;
    background-color: white;
    overflow-y: scroll;
    max-height: 65vh;
}

.modal-content-body::-webkit-scrollbar {
    width: 10px;
}

.modal-content-body::-webkit-scrollbar-track {
    background: rgba(195, 195, 195);
}

.modal-content-body::-webkit-scrollbar-thumb {
    background-color: rgba(121, 121, 121, 0.411);
    border-radius: 30px;
}
.modal-description {
    padding: 0px 30px;
}