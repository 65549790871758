/* ------------------------------------------------ */
/* DNS base styling                            */
/* ------------------------------------------------ */

/* Used for as containers for sections of the DNS page */
.DNS-Response-Container-Transparent {
  border-radius: 10px;
  margin: 2% 0%;
  line-height: 2.0;
  min-width: 685px;
}

/* Active zones container */
.DNS-User-Zones-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}

/* Add DNS zone card */
.DNS-User-Zones-card-add {
  width: 350px;
  min-height: 160px;
  background-color: #fff;
  border: 2px dashed #d9d9d9;
  border-radius: 10px;
  display: grid;
  place-items: center;
  margin: 2% 2% 0% 0%;
  padding: 1%;
}

.DNS-User-Zones-card-add:hover {
  cursor: pointer;
  transform: scale(1.02);
  border-color: #0C6CDE;
}

/* Active zone Cards */
.DNS-User-Zones-card-container {
  display:grid;
  min-height: 160px;
  grid-template-rows: min-content auto 80px;
  grid-template-areas:
    "card-top"
    "card-middle"
    "card-bottom";
  width: 350px;
  background-color: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  padding: 1%;
  margin: 2% 2% 0% 0%;
}

.DNS-User-Zones-card-top {
  grid-area: card-top;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "card-top-text card-top-icon";
  display: grid;
}

.DNS-User-Zones-card-title {
  grid-area: card-top-text;
  max-width: 100%;
  word-break: break-all;
  overflow: hidden;
  font-size: var(--fontsize-subheader);
  font-family: var(--bold);
  padding: 0px 0px 20px 0px;
}

.DNS-User-Zones-card-top-text {
  grid-area: card-top-text;
  font-size: 14px;
}

.DNS-User-Zones-card-top-icon {
  grid-area: card-top-icon;
  cursor: pointer;
  padding: 40% 0% 0% 5%;
}

.DNS-User-Zones-card-top-icon:hover {
  transform: scale(1.1);
}

.DNS-User-Zones-card-middle {
  grid-area: card-middle;
  max-width: 100%;
  overflow: hidden;
  font-size: 14px;
  padding: 0px 0px 20px 0px;
}

.DNS-User-Zones-card-bottom {
  grid-area: card-bottom;
  max-width: 100%;
  word-break: break-all;
  overflow: hidden;
  font-size: 14px;
}
