/* CSS Class - Sidebar Item */

.NavItem{
    transition: 0.15s;
    border-radius: 5px;
}

.NavItem a:link {
    color: #e0e6e9;
    text-decoration: none;
}

.NavItem a:link:hover {
    text-decoration: none;
}

.NavItem:hover{
    color: white;
    background-color: #3E4550;
    text-decoration: none;
}

.NavLink {
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    align-items: center;
    font-size: var(--fontsize-body);
    color: #e0e6e9;
    text-decoration: none;
    padding: 10px 15px;
}

/* CSS Class - Sidebar Item When Clicked */

.NavItem-Clicked{
    background-color: #3E4550;
    border-radius: 5px;
}