.App {
    box-sizing: border-box;
}

/* Ensures if you change the box-sizing of an element, everything inside the element will inherit the box-sizing of that element */
*,
*:before,
*:after {
    box-sizing: inherit;
}

/*Ensures all images will be resized according to screen size*/
img {
    max-width: 100%;
}

/* ========================== */
/*  Responsive Grid           */
/* ========================== */

/* CSS Grid with Sidebar OPEN */

.Wrapper {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 60px 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "Sidebar NavBar"
        "Sidebar PageContent";
    min-width: 900px;
}

/* -------------------------- */
/*  NavBar                    */
/* -------------------------- */

.NavBar {
    grid-area: NavBar;
    height: 40px;
    width: calc(100% - 30px);
    background-color: white;
    box-shadow: 0px 2.77296px 5.54593px -2.21837px rgba(31, 31, 31, 0.25);
    z-index: 100;
    top: 0;
    left: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
}

/* -------------------------- */
/*  Sidebar                   */
/* -------------------------- */

.Sidebar {
    grid-area: Sidebar;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 50px 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "Sidebar-Row1-Logo"
        "Sidebar-Row2-Home"
        "Sidebar-Row3-Manage"
        "Sidebar-Row4-Monitor"
        "Sidebar-Row5-Support";
    width: 200px;    
    height: 100%;
    position: fixed;
    background-color: var(--sidebar-colour);
    transition: .5s ease;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px 25px;
    color: #D9D9D9;
}

.Sidebar a {
    text-decoration: none;
    display: block;
}

.Sidebar::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

.Sidebar::-webkit-scrollbar-track {
    background: rgba(195, 195, 195);
}

.Sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(121, 121, 121, 0.411);
    border-radius: 30px;
    border: none;
}


/*  Sidebar Grid Items         */

.Sidebar-Row1-Logo {
    grid-area: Sidebar-Row1-Logo;
    text-align: center;
}

.Sidebar-Row2-Home {
    grid-area: Sidebar-Row2-Home;
}

.Sidebar-Row3-Manage {
    grid-area: Sidebar-Row3-Manage;
    border-top: 0.5px solid #505B69;
    padding: 10px 0px;
}

.Sidebar-Row4-Monitor {
    grid-area: Sidebar-Row4-Monitor;
    border-top: 0.5px solid #505B69;
    padding: 15px 0px;
}

.Sidebar-Row5-Support {
    grid-area: Sidebar-Row5-Support;
    border-top: 0.5px solid #505B69;
    padding: 15px 0px;
}

/* -------------------------- */
/*  Page Content              */
/* -------------------------- */


.PageContent {
    grid-area: PageContent;
}

/* CSS Grid with Sidebar CLOSED */
.Wrapper-NoSidebar {
    display: grid;
    gap: 0px 0px;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr;
    grid-template-areas:
        "NavBar"
        "PageContent";
}

.NavBar-NoSidebar {
    grid-area: NavBar;
    height: 40px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2.77296px 5.54593px -2.21837px rgba(31, 31, 31, 0.25);
    z-index: 100;
    top: 0;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
}

.NoSidebar {
    display: "none"
}

.PageContent-NoSidebar {
    grid-area: PageContent;
}