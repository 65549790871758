/* ------------------------------------- */
/*  Sustainability Response              */
/* ------------------------------------- */

.Sustainability-Response-Pending {
    background-color: white;
    border-radius: 5px;
    border-bottom: 1px solid rgb(208, 208, 208);
    border-right: 1px solid rgb(208, 208, 208);
    padding: 50px 15px;
    min-width: 685px;
    text-align: center;
}

.Sustainability-Response-Success {
    background-color: white;
    border-radius: 5px;
    border-bottom: 1px solid rgb(208, 208, 208);
    border-right: 1px solid rgb(208, 208, 208);
    min-width: 685px;
    font-size: var(--fontsize-body);
}

.Sustainability-Response-Error {
    background-color: white;
    border-radius: 5px;
    border-bottom: 1px solid rgb(208, 208, 208);
    border-right: 1px solid rgb(208, 208, 208);
    min-width: 685px;
    font-size: var(--fontsize-body);
    padding: 20px;
}

/* ------------------------------- */
/*  Project Emissions Table        */
/* ------------------------------- */

table.Project-Emissions-Table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    text-align: left;
}

table.Project-Emissions-Table tr {
    border-bottom: 0.5px solid lightgray;
}

table.Project-Emissions-Table tr th:first-child {
    padding-left: 50px;
}

table.Project-Emissions-Table tr td:first-child {
    padding: 20px 50px;
}

table.Project-Emissions-Table th {
    padding: 30px 20px;
    font-weight: bold;
    font-size: var(--fontsize-body);
}

table.Project-Emissions-Table td {
    padding: 20px;
    line-height: 1.7;
    word-wrap: break-word;
    font-size: var(--fontsize-body);
}

/* Expand Icon */

.Expand-Icon:hover {
    cursor: pointer;
}

/* ------------------------------- */
/*  Service Emissions Table        */
/* ------------------------------- */

table.Service-Emissions-Table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    text-align: left;
}

table.Service-Emissions-Table tr {
    border: none;
}

table.Service-Emissions-Table tr th:first-child {
    padding-left: 30px;
    border-bottom: 0.5px solid rgb(234, 234, 234);

}

table.Service-Emissions-Table tr td:first-child {
    padding: 10px 35px;
}

table.Service-Emissions-Table th {
    padding: 10px;
    font-weight: bold;
    background-color: #E9F6FF;
}

table.Service-Emissions-Table td {
    padding: 15px;
    line-height: 1.7;
    border-bottom: 0.5px solid rgb(236, 236, 236);
}

/* ------------------------------- */
/*  Unattended Projects Table      */
/* ------------------------------- */

.Unattended-Projects-Row-Container{
    display: flex;
    flex-direction: column;
}

table.Unattended-Projects-Table {
    border-collapse: collapse;
    border: 1px solid rgb(234, 234, 234);
    table-layout: fixed;
    width: 100%;
    text-align: left;
}

table.Unattended-Projects-Table tr td:first-child {
    padding: 10px 20px;
}

table.Unattended-Projects-Table td {
    padding: 10px 15px;
    line-height: 1.7;
    border-bottom: 0.5px solid rgb(236, 236, 236);
}

.UPR-Total-Label{
    display: flex;
    flex-direction: row;
    gap: 0px 5px;
    align-items: flex-end;
}

.UPR-Recommendation-Label{
    background-color: #F2FAFF;
    padding: 10px;
    border-radius: 5px;
    margin: 15px 0px;
    width: 98%;
}
