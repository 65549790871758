/* Refer to App.css for Header CSS class */

/* Profile Dropdown */

.NavBar-Profile-Dropdown {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 5px 10px 35px 3px rgba(81, 81, 81, 0.35);
  visibility: hidden;
  transition-duration: 0.3s;
}

.NavBar-Profile-Dropdown:hover {
  visibility: visible;
}

.NavBar-Profile-Icon {
  margin: 0px 15px;
  border-radius: 50%;
  background-color: #FC3C78;
  color: white;
  padding: 10px;
  letter-spacing: 1px;
  cursor: pointer;
}

.NavBar-Profile-Icon:hover~.NavBar-Profile-Dropdown {
  visibility: visible;
}

.NavBar-Button {
  width: 125px;
  height: 50px;
  padding: 0px 10px;
  font-family: var(--body);
  font-size: var(--fontsize-body);
  background-color: white;
  cursor: pointer;
  color: #272b30;
  border: none;
}

.NavBar-Button:hover {
  background-color: #F4F5F7;
  color: #0C6CDE;
  visibility: visible;
}

.Logout-Icon {
  margin: 0px 10px 0px 0px;
}

/* ---------------------------------- */
/*  Global Selector Dropdown          */
/* -----------------------------------*/

.Global-Selector-Dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: none;
  font-family: var(--body);
  font-size: var(--fontsize-body);
  padding: 10px 15px;
  margin: 0px 20px;
  border-radius: 5px;
}

.Global-Selector-Dropdown:hover {
  background-color: #F0F0F0;
}

.Global-Selector-Dropdown p {
  margin: 0px 15px;
}

/* ---------------------------------- */
/*  Global Selector Modal             */
/* -----------------------------------*/

.Global-Selector-Modal-Container {
  z-index: 2;
  position: absolute;
  width: 50vw;
  min-width: 480px;
  min-height: 20%;
  max-height: 60%;
  top: 17.5%;
  left: calc(50% + 250px);
  transform: translate(calc(-50% - 250px), 0);
  background-color: #FFFFFF;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 5px;
}

/* Modal Header */

.Global-Selector-Modal-Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--fontsize-subheader);
  padding: 0px 5px;
}

.Global-Selector-View-Dropdown {
  background-color: white;
  border: none;
  font-family: var(--body);
  font-size: var(--fontsize-body);
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Global-Selector-Modal-Header-Left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px 15px;
}

.Global-Selector-Modal-Header-Right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px 20px;
}

.Global-Selector-Modal-Header-Right img {
  cursor: pointer;
}

/* Modal Message */

.Global-Selector-Message {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0px;
  padding: 12px 20px;
  background-color: #f4f4f4;
  border-radius: 5px;
  font-size: 15px;
}

.Global-Selector-Info-Icon {
  width: 15px;
  height: 15px;
  margin-right: 15px;
}

/* Modal Body - Resource Table */

.Global-Selector-Resource-Table-Container{
  overflow-x: hidden;
  overflow-y: auto;
}

.Global-Selector-Resource-Table-Container::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.Global-Selector-Resource-Table-Container::-webkit-scrollbar-track {
  background: rgba(195, 195, 195);
}

.Global-Selector-Resource-Table-Container::-webkit-scrollbar-thumb {
  background-color: rgba(121, 121, 121, 0.411);
  border-radius: 30px;
  border: none;
}

.Global-Selector-Resource-Table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  text-align: left;
  font-size: 15px;
  border-bottom: 0.5px solid lightgray;
}

table.Global-Selector-Resource-Table th {
  padding: 10px 15px;
  border-bottom: 0.5px solid lightgray;
}
 
table.Global-Selector-Resource-Table td {
  padding: 8px 15px;
}

table.Global-Selector-Resource-Table tr {
  cursor: pointer;
}

.Global-Selector-Resource-Row {
  background-color: white;
}

.Global-Selector-Resource-Row:hover{
  background-color: #F0F5FE;
}

.Global-Selector-Resource-Row-Active {
  background-color: #F0F5FE;
}

.Global-Selector-Resource-Link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  word-break: break-all;
}

.Global-Selector-Description {
  overflow: hidden;
  line-height: 1.6;
  text-overflow: ellipsis;
  display: -webkit-box; /* fallback */
  height: 1rem; /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.Global-Selector-Resource-Labels {
  padding: 5px 8px;
  border-radius: 5px;
}


/* Modal Footer */

.Global-Selector-Modal-Footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
}

.Global-Selector-Cancel{
  border: none;
  background-color: white;;
  font-family: var(--body);
  font-size: 15px;
  cursor: pointer;
}

.Global-Selector-Open {
  border: none;
  color: var(--button-action-colour);
  background-color: white;;
  font-family: var(--body);
  font-size: 15px;
  cursor: pointer;
}

.Global-Selector-Open:disabled {
  color: rgb(157, 157, 157);
}