/* ============================================== */
/*  Import Fonts                                  */
/* ============================================== */


@font-face {
  src: url(Fonts/CircularStd-Book_1.ttf);
  font-family: circular-body;
}

@font-face {
  src: url(Fonts/CircularStd-Bold_0.ttf);
  font-family: circular-bold;
}

@font-face {
  src: url(Fonts/CircularStd-Medium.ttf);
  font-family: circular-subheader;
}


/* ============================================== */
/*  Root CSS Variables                            */
/* ============================================== */


:root {
  --body: Circular-body;
  --fontsize-body: 16px;
  --fontsize-header: 25px;
  --subheader: Circular-subheader;
  --fontsize-subheader: 22px;
  --subheader: Circular-subheader;
  --bold: Circular-bold;
  --fontsize-label: 13px;
  --button-action-colour: #0C6CDE;
  --button-action-colour-hover: #0060cd;
  --button-CTA-colour: #FC3C78;
  --button-CTA-colour-hover: #F21258;
  --button-cancel-colour: #0C6CDE;
  --button-cancel-colour-hover: #00499F;
  --button-font: Circular-body;
  --navbar-colour: #282E36;
  --sidebar-colour: #282E36;
  --navitem-colour: #282E36;
  --link-text-colour: #0C6CDE;
  --ll-green: #06B448;
  --ll-yellow: #FCE809;
  --ll-red: #FC360C;
  --tooltip-background-colour: #343434;
  --inputfield-placeholder-color: #c4c4c4;
}

html {
  background-color: #F9F9FB;
  background-repeat: no-repeat;
  background-size: cover;
  color: rgb(0, 0, 0);
}

body {
  margin: 0;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  font-family: Circular-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-anchor: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="date"]{
  width: 125px; 
  height: 20px;
}

.FormResponse-Container {
  padding-right: 15%;
  padding-left: 15%;
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: center;
}

.form-submit-button {
  background-color: var(--button-action-colour, #0C6CDE);
  border: none;
  color: white;
  padding: 15px 30px;
  margin: 40px 0px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: var(--fontsize-button);
  text-align: center;
  font-family: var(--button-font);

}

.form-submit-button:hover {
  transform: scale(1.01);
}

.form-submit-button-secondary {
  background-color: white;
  border: 1px solid #0C6CDE;
  color: #0C6CDE;
  padding: 15px 30px;
  margin: 40px 0px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: var(--fontsize-button);
  text-align: center;
  font-family: var(--button-font);
}

.form-submit-button-secondary:hover {
  transform: scale(1.01);
}

.form-submit-button:disabled {
  background-color: rgb(233, 233, 233);
  color: rgb(199, 198, 198);
  box-shadow: none;
  cursor: not-allowed;
}


/* ============================================== */
/*  Default Page Container                        */
/* ============================================== */

.PageComponent-Container {
  padding: 2%;
  display: grid;
  grid-template-areas:
    'Header'
    'Body';
}

.PageComponent-Header {
  grid-area: Header;
}

.PageComponent-Body {
  grid-area: Body;
}

.PageComponent-Label{
  background-color: #eeeeee;
  color: #4d4d4d;
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.PageComponent-LabelMessage{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PageComponent-SelectButton{
  border: none;
  color: var(--button-action-colour);
  font-family: var(--button-font);
  font-size: 15px;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
}

.PageComponent-SelectButton:hover {
  background-color: rgb(223, 222, 222);
}

.PageComponent-AccessDenied {
  text-align: center;
  margin-top: 1%;
}

.PageComponent-AccessDenied img {
  max-width: 300px;
}

.PageComponent-Errors-Container{
  text-align: center;
  padding: 1%;
}

.PageComponent-Errors-Container img {
  width: 15%;
}

.PageComponent-Errors-Container p {
  line-height: 1.7;
}

/* ============================================== */
/*  Headings                                      */
/* ============================================== */

.Page-Subheading {
  padding: 5px;
  font-weight: normal;
  font-family: var(--subheader);
  font-size: var(--fontsize-subheader)
}

/* ============================================== */
/*  Modal Background                              */
/* ============================================== */

.Modal-Background {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* ============================================== */
/*  Form                                          */
/* ============================================== */

.Form-Container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  min-width: 60%;
  max-width: 100%;
  height: 100%;
}

.Form-Body {
  display: flex;
  flex-flow: column;
  border-radius: 5px;
  padding: 2% 3%;
  background-color: white;
  border-bottom: 1px solid rgb(212, 212, 212);
  border-right: 1px solid rgb(212, 212, 212);
}

/* ============================================== */
/*  Buttons                                       */
/* ============================================== */

/* ------------------------------------- */
/*  Primary Button                       */
/*  (Standard - Blue with white text)    */
/* ------------------------------------- */

.Primary-Button {
  color: white;
  background-color: var(--button-action-colour);
  font-family: var(--button-font);
  font-size: var(--fontsize-body);
  border: none;
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  text-decoration: none;
  min-width: fit-content;
}

.Primary-Button:hover {
  color: white;
  background-color: var(--button-action-colour-hover);
}

.Primary-Button:disabled {
  color: rgb(171, 171, 171);
  background-color: rgb(233,233,233);
}

/* ------------------------------------- */
/*  Secondary Button                     */
/*  (Cancel - White with blue text)      */
/* ------------------------------------- */

.Secondary-Button {
  background-color: white;
  color: var(--button-action-colour);
  font-family: var(--button-font);
  font-size: var(--fontsize-body);
  border: 1px solid var(--button-action-colour);
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  text-decoration: none;
  min-width: fit-content;
}

.Secondary-Button:hover {
  background-color: rgb(249, 249, 249);
}

.Secondary-Button:disabled {
  color: rgb(171, 171, 171);
  background-color: rgb(233,233,233);
}

/* ------------------------------------- */
/*  Button Group                         */
/*  (Submit/Cancel Buttons               */
/* ------------------------------------- */

.Button-Group {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 20px 0px;
}

/* ============================================== */
/*  Tables                                        */
/* ============================================== */

/* ------------------------------------- */
/*  Table Pane                           */
/*  (White background behind table)      */
/* ------------------------------------- */

.Table-Pane {
  background-color: white;
  padding: 10px 25px;
  border-radius: 5px;
}

/* ------------------------------------- */
/*  Data Tables                          */
/* ------------------------------------- */

.Data-Table-Container{
  font-size: var(--fontsize-body);
  background-color: white;
  border: 1px solid #B9B9B9;
  border-radius: 5px;
  margin: 10px 0px;
  border-collapse: collapse;
  min-width: 685px;
  line-height: 1.6;
  width: 100%;
}

.Data-Table-Container tr {
  border: 1px solid #B9B9B9;
  text-align: left;
}

.Data-Table-Container tr:first-child {
  border-bottom: none;
}

.Data-Table-Container th {
  background-color: #F0F0F0;
  font-weight: bold;
  padding: 10px 10px;
}

.Data-Table-Container th:first-child {
  padding-left: 30px;
}

.Data-Table-Container th:last-child {
  padding-right: 30px;
}

.Data-Table-Container td {
  padding: 20px 10px;
}

.Data-Table-Container td:first-child {
  padding-left: 30px;
}

.Data-Table-Container td:last-child {
  padding-right: 30px;
}

/* ================================================================== */
/*  Custom Scroll bBar                                                 */
/*  https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp    */
/* ================================================================== */

.Custom-Scroll-Bar {
  overflow-y: auto;
}

.Custom-Scroll-Bar::-webkit-scrollbar {
  width: 10px;
}

.Custom-Scroll-Bar::-webkit-scrollbar-track {
  background: #f3f3f3;
}

.Custom-Scroll-Bar::-webkit-scrollbar-thumb {
  background: #0c6bde68;
  border-radius: 10px;
}

/* Login Styling */

.Header {
  display: flex;
  align-items: center;
  height: 90px;
  background-color: #282E36;
  padding-left: 8rem;
}

.Footer {
  display: flex;
  justify-content: center ;
  align-items: center;
  height: 80px;
  background-color: #FAF9F9;
}

.Login-Body-Container {
  min-height: calc(100vh - 170px);
  background-color: #E2F0FE;
}

.Login-Grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 8rem;
  place-items: center;
}

.Grid-Left {
  grid-column: 1;
}

.Grid-Right {
  grid-column: 2;
}

.Login-Grid p {
  font-family:  var(--light);
  line-height: 1.7;
  max-width: 570px;
  padding-bottom: 1rem;
}

.Login-Grid h1 {
  font-size: 48px;
}

.Grid-Image {
  object-fit: contain;
  width: 500px;
}

/* ================================================================== */
/*  Tooltips                                                          */
/* ================================================================== */

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  text-align: left;
  font-family: var(--body);
  width: fit-content;
  min-width: 300px;
  background-color: var(--tooltip-background-colour, #343434);
  color: #FFFFFF;
  font-size: var(--fontsize-body);
  line-height: 1.4;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  z-index: 100;

  /* Position the tooltip */
  position: absolute;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* --------------------------- */
/*  Slider/Toggle              */
/* --------------------------- */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #0C6CDE;
}

input:focus+.slider {
  box-shadow: 0 0 1px #0C6CDE;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}




