.check-box-container{
    font-size: var(--fontsize-body);
    margin-left: 5px;
    margin-right: 5px;  
    margin-top: 8px;
    margin-bottom: 18px;
}

.check-box-container textarea{
    margin-top: 15px;
    margin-bottom: 0px;
}
    
