/* =============================================== */
/*  Sign Page                                      */
/* =============================================== */

/* ===============================================

    Failed and Error pages

    Generic grid that supports 2 columns and 3 rows, layout is below:
        'grumpy-cat title'
        'grumpy-cat solution'
        'grumpy-cat button'
        'error error' 

=============================================== */

.LoginHandler-Failed-Container {
    display: grid;
    text-align: left;
    padding: 15%;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        'LoginHandler-Failed-Img LoginHandler-Failed-Title'
        'LoginHandler-Failed-Img LoginHandler-Failed-Solution'
        'LoginHandler-Failed-Img LoginHandler-Failed-Button'
        'LoginHandler-Failed-Error LoginHandler-Failed-Error';
}

.LoginHandler-Failed-Img {
    grid-area: LoginHandler-Failed-Img;
    max-width: 550px;
}

.LoginHandler-Failed-Title {
    grid-area: LoginHandler-Failed-Title;
    padding-left: 5%;
}

.LoginHandler-Failed-Solution {
    grid-area: LoginHandler-Failed-Solution;
    padding-left: 5%;
}

.LoginHandler-Failed-Button {
    grid-area: LoginHandler-Failed-Button;
    padding-left: 5%;
}

.LoginHandler-Failed-Error {
    grid-area: LoginHandler-Failed-Error;
    text-align: center;
    padding-top: 5%;
}

/* ===============================================
    Pending pages containers
        'pending'
=============================================== */

.LoginHandler-Pending {
    text-align: center;
    padding: 10% 15%;
}

/* =============================================== */
/* Buttons */
/* =============================================== */

.LoginHandler-TryAgain-Button {
    width: fit-content;
    background-color: var(--button-action-colour, #0C6CDE);
    font-family: var(--button-font);
    font-size: var(--fontsize-button);
    color: white;
    padding: 10px 30px;
    border-radius: 5px;
    border: none;
    height: fit-content;
    margin: 20px 0px;
}

.LoginHandler-TryAgain-Button:hover {
    background-color: var(--button-action-colour-hover, #00499F);
}