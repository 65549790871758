/* ============================= */
/* Parent container */
/* ============================= */

.GroupManagement-Groups-Container {
    display: grid;
    grid-template-columns: 600px 1fr; 
    grid-template-areas: 
    'Search .'
    'Members .'
    'Buttons Buttons';
}

/* ============================= */
/* Generic */
/* ============================= */

.GroupManagement-Label{
    display: flex;
    align-items: center;
    background-color: #eeeeee;
    color: #4d4d4d;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.GroupManagement-Icon:hover {
    transform: scale(1.05);
    cursor: pointer;
}

/* ============================= */
/* Search container & children */
/* ============================= */

.GroupManagement-Search{
    grid-area: Search;
    display: flex;
    align-items: center;
}
.GroupManagement-Search img {
    margin-left: 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

/* ============================= */
/* Members container & children */
/* ============================= */

.GroupManagement-Members{
    grid-area: Members;
    min-height: 100px;
}
.GroupManagement-EmailAddress {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border-radius: 5px;
}

.GroupManagement-InviteForm {
    padding: 10px;
    display: flex;
    flex-direction: column;
    
}

/* ============================= */
/* Buttons container & children */
/* ============================= */

.GroupManagement-Buttons {
    grid-area: Buttons;
    margin-top: 2%;
}