.NotFound-Container{
    padding: 10% 15%;
    text-align: center;
    height: fit-content;
    background-color: rgb(253, 253, 253);
    overflow-anchor: none;
}

.NotFound-Title {
    font-size: var(--fontsize-subheader);
    font-weight: lighter;
    padding: 0% 0% 1% 0%;
    margin: 0%;
  }