.Consumption-Container{
    line-height: 1.7;
    margin: 2% 0%;
    padding: 10px 20px 30px 20px;
    text-align: left;
    background-color: white;
    border-radius: 5px;
    min-width: 685px;
    font: var(--body);
    display: grid;
    grid-template-areas:
    'table'
}

.Consumption-Table {
    grid-area: table;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    text-align: left;
}

.Consumption-Table tr {
    border-bottom: 0.5px solid lightgray;
}

.Consumption-Table th {
    font-weight: bold;
    padding: 20px;
    line-height: 1.7;
    word-wrap: break-word;
    font-size: var(--fontsize-body);
}

.Consumption-Table td {
    padding: 20px;
    line-height: 1.7;
    word-wrap: break-word;
    font-size: var(--fontsize-body);
}

.Consumption-Table-FY:hover{
    background-color: rgb(237, 249, 253);
    cursor: pointer;
}

/* Success - First Container */
.Consumption-View-Container-Success-1{
    padding: 15px 20px;
    display: grid;
    grid-gap: 20px;
    grid-template-areas: 
    'subtitle1 subtitle1 navbar'
    'table1 table1 table1'
    'subtitle2 subtitle2 subtitle2'
    'barchart barchart barchart'
}

.Consumption-Navbar-Container{
    grid-area: navbar;
    text-align: end;
}

.Consumption-Navbar {
    background-color: var(--button-action-colour, #0C6CDE);
    font-family: Circular-body;
    font-size: var(--fontsize-body);
    color: white;
    border-radius: 5px;
    border: none;
    height: 30px;
    width: 50px;
    cursor: pointer;
}

.Consumption-Navbar:hover {
    background-color: var(--button-action-colour-hover, #00499F);
}

.Consumption-Trends-Container{
    display: flex;
    flex-direction: row;
    align-content: center;
}

.Consumption-View-Subtitle-1{
    grid-area: subtitle1;
}

.Consumption-View-Table-1 {
    grid-area: table1;
}

.Consumption-View-Subtitle-2{
    grid-area: subtitle2;
}


.Consumption-View-Chart {
    grid-area: barchart;
    display: flex;
    justify-content: center;
    width: 100%;
}

/* Success - Second Container */
.Consumption-View-Container-Success-2{
    display: grid;
    grid-gap: 20px;
    grid-template-areas: 
    'subtitle3 subtitle3 subtitle3'
    'table2 table2 table2'
}

.Consumption-View-Subtitle-3{
    grid-area: subtitle3;
    display: flex;
    justify-content: space-between;
}

.Consumption-View-Table-2 {
    grid-area: table2;
}


/* Dashboard */

.Consumption-Dashboard{
    display: flex;
    flex-direction: column;
    gap: 20px;
}