/* --------------------------- */
/*  Summary Pane               */
/* --------------------------- */

.Sustainability-Summary-Pane {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    grid-template-areas:
        "Metric-1 Metric-2 Metric-3";
    margin-bottom: 2%;
    padding: 15px;
    border-bottom: 1px solid rgb(208, 208, 208);
    border-right: 1px solid rgb(208, 208, 208);
    background-color: white;
    border-radius: 5px;
    min-width: 685px;
    align-items: top;
    line-height: 2.0;
}

.Metric-1 {
    grid-area: Metric-1;
    padding: 10px 25px;
    border-right: 1px solid rgb(208, 208, 208);
}

.Metric-2 {
    grid-area: Metric-2;
    padding: 10px 25px;
    border-right: 1px solid rgb(208, 208, 208);

}

.Metric-3 {
    grid-area: Metric-3;
    padding: 10px 25px;

}

.Monthly-Emissions-Total {
    display: flex;
    flex-direction: row;
    align-items: top;
    gap: 15px;
}

.Monthly-Emissions-Trend-Up {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FC360C;
}

.Monthly-Emissions-Trend-Down {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #06B448;
}

/* --------------------------- */
/*  Filter Pane                */
/* --------------------------- */

.Sustainability-Filter-Pane {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2%;
    padding: 10px 20px;
    border-bottom: 1px solid rgb(208, 208, 208);
    border-right: 1px solid rgb(208, 208, 208);
    background-color: white;
    border-radius: 5px;
    min-width: 685px;
    align-items: center;
    gap: 0px 10px;
}

.Sustainability-Filter-Pane h1 {
    font-size: var(--fontsize-subheader);
    font-weight: bold;
}


/* --------------------------- */
/*  Modal                      */
/* --------------------------- */

.UPR-Modal {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "UPR-FAQs UPR-FAQs UPR-Links";
    padding: 0px 30px;
    line-height: 1.7;
}

.UPR-FAQs {
    grid-area: UPR-FAQs;
    padding: 5px 15px 10px 5px;
}

.UPR-Links {
    grid-area: UPR-Links;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 15px;
}