.option {
  font-family: var(--body);
  width: 250px;
  min-width: 250px;
  padding: 12px 20px;
  margin: 5px 0px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
  font-weight: normal;
}

.dropdown-empty {
  background-color: blue;
  width: 250px;
  min-width: 250px;
  padding: 12px 20px;
  margin: 5px 0px;
  color: rgb(151, 151, 151);
  font-weight: normal;
}

.dropdown {
  background-color: blue;
  width: 250px;
  min-width: 250px;
  padding: 12px 20px;
  margin: 5px 0px;
  color: black;
  font-weight: normal;
}