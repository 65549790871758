.Security-Container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

/* Overview of the below classes */
/* ------------------------------------------------ */
/*                     Header                       */
/* ------------------------------------------------ */
/*                     Dashboard                    */
/* ------------------------------------------------ */
/* |               |                              | */
/* |               |                              | */
/* |               |                              | */
/* |               |                              | */
/* |   Findings    |  Description                 | */
/* |               |                              | */
/* |               |                              | */
/* |               |                              | */
/* |               |                              | */
/* |               |                              | */
/* ------------------------------------------------ */

/* Header Container */
.Security-Header {
    grid-area: Header;
    font-size: var(--fontsize-subheader);
    padding: 15px;
    display: flex;
    flex-direction: row;
    min-width: 850px;
    border-bottom: 20px solid #F9F9FB;
    align-items: center;
    justify-content: space-between;
}

.Security-Filter-Pane{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin: 0px 10px;
    font-size: 20px;
}


/* Dashboard */
.Security-Dashboard {
    grid-area: Dashboard;
    padding: 15px;
    display: flex;
    justify-content: space-around;
    min-width: 850px;
    border-bottom: 20px solid #F9F9FB;
}

.Security-Dashboard-Info {
    display: flex;
    flex-direction: column;
    align-self: center;
    line-height: 1.6;
}

.Security-Dashboard-Findings {
    display: flex;
    align-items: center;
}

.Security-Dashboard-Findings img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    cursor: pointer;
}

.Findings-By-Severity-Container{
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    justify-content: center;
}

.Findings-By-Severity{
    display: flex;
    flex-direction: row;
    gap: 30px;
}

/* Security Findings Summary */

.No-Findings-Container {
    background-color: white;
    height: fit-content;
    padding: 10px 20px;
}

.Security-Findings-Summary{
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
        'Findings Description';
    background-color: #fff;
    height: 80vh;
}

/* Findings Container */
.Security-Findings {
    grid-area: Findings;
    overflow-y: scroll;
    margin: 10px;
}

.Security-Findings::-webkit-scrollbar {
    width: 10px;
}

.Security-Findings::-webkit-scrollbar-track {
    background: #f3f3f3;
}

.Security-Findings::-webkit-scrollbar-thumb {
    background: #c6e3ff;
    border-radius: 10px;
}

/* Child Item */
.Security-Findings-Item {
    padding: 15px;
    border-bottom: 1px solid #e9e9e9;
    overflow: hidden;
    cursor: pointer;
    margin-left: 5px;
}

.Security-Findings-Item:hover {
    border-left: 5px solid #c6e3ff;
    margin-left: 0px;
}

.Security-Findings-Item div {
    font-size: var(--fontsize-subheader);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.Security-Findings-Item p {
    display: flex;
    flex-direction: column;
}

.Security-Findings-Item span {
    padding-top: 8px;
    padding-left: 10px;
}

/* Description Container */
.Security-Description {
    grid-area: Description;
    margin: 10px;
    overflow-x: scroll;
}

.Security-Description::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.Security-Description::-webkit-scrollbar-track {
    background: #f3f3f3;
}

.Security-Description::-webkit-scrollbar-thumb {
    background: #c6e3ff;
    border-radius: 10px;
}