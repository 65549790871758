.component-response-container {
  background-color: white;
  border-radius: 2px;
  border-bottom: 1px solid rgb(208, 208, 208);
  border-right: 1px solid rgb(208, 208, 208);
  margin: 2%;
  padding: 15px;
  min-width: 685px;
  overflow: auto;
}

.component-container {
  background-color: white;
  border-radius: 2px;
  border-bottom: 1px solid rgb(208, 208, 208);
  border-right: 1px solid rgb(208, 208, 208);
  margin: 2% 0% 3% 0%;
  min-width: 685px;
  overflow: auto;
}

/* Scroll bar customisation */
.component-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.component-container::-webkit-scrollbar-track {
  background: rgb(233, 233, 233);
}

.component-container::-webkit-scrollbar-thumb {
  background-color: rgba(121, 121, 121, 0.411);
  border-radius: 20px;
  border: none;
}

.component-response-container-pending {
  background-color: white;
  border-radius: 2px;
  margin: 2% 2% 3% 2%;
  padding: 50px 15px;
  min-width: 685px;
  text-align: center;
}


table.component-response {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  text-align: left;
}


table.component-response tr {
  border-bottom: 0.5px solid lightgray;
}

table.component-response tr td:first-child {
  padding-left: 50px;
}

table.component-response tr th:first-child {
  padding-left: 50px;
}

table.component-response th {
  padding: 30px 20px;
  font-weight: bold;
  font-size: var(--fontsize-body);
}

table.component-response td {
  padding: 20px;
  line-height: 1.7;
  word-wrap: break-word;
  font-size: var(--fontsize-body);
}

.component-response-link-parent {
  text-decoration: none;
}

.component-response-link {
  display: flex;
  align-content: center;
}

.component-response-link-img {
  cursor: pointer;
  transition: transform .2s;
}

.component-response-link-img:hover {
  transform: scale(1.3);
}

.component-response-link-tooltip {
  visibility: hidden;
  /* text-align: center; */
  font-family: var(--body);
  width: fit-content;
  background-color: var(--tooltip-background-colour, #343434);
  color: #FFFFFF;
  font-size: var(--fontsize-body);
  line-height: 1.4;
  border-radius: 3px;
  padding: 5px 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  transform: translateX(10px);
}

.component-response-link-img:hover ~ .component-response-link-tooltip {
  visibility: visible;
  text-decoration: none;
}

.icon {
  word-wrap: break-word;
  font-size: var(--fontsize-body);
  text-align: center;
  border-radius: 2px;
  padding: 5px;
  min-width: 40px;
  min-height: 40px;
}

.icon:hover {
  background-color: #e9f1ff;
  padding: 5px;
}

.table-message {
  line-height: 1.8;
  font-size: var(--fontsize-body);
}

.table-stop-button-container {
  margin-top: 80px;
}

.table-stop-button {
  padding: 10px 15px;
  margin: 0px 10px;
  font-size: var(--fontsize-body);
  font-family: var(--body);
  border: none;
  box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
  background-color: var(--button-cancel-colour, #fc3c78);
  color: white;
  border-radius: 3px;
}

.table-stop-button:hover {
  background-color: var(--button-cancel-colour-hover, #f21258);
  color: white;
}

.try-again-button {
  background-color: var(--button-action-colour, #0C6CDE);
  border: none;
  color: white;
  padding: 15px 30px;
  margin: 10px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: var(--fontsize-button);
  text-align: center;
  font-family: var(--body, Circular-body);
}

.try-again-button:hover {
  background-color: var(--button-action-colour-hover, #00499F);
}
