.Budget-Header {
    display: flex;
    text-align: center;
}

.Budget-Search {
    display: flex;
    flex-direction: row;
    width: 300px;
    margin-right: 20px;
}