/* --------------------------- */
/*  Portfolio Page             */
/* --------------------------- */

/* Cards Container */
.Cards-Container {
  display: flex;
  margin: 2rem 0;
  gap: 2rem;
  flex-wrap: wrap;
}

/* Add Portfolio Card */
.Add-Card {
  display: grid;
  place-items: center;
  border: 2px dashed #d9d9d9;
}

/* Add Portfolio Card -> Hover */
.Add-Card:hover {
  cursor: pointer;
  transform: scale(1.03);
  border: 2px dashed #4285f4;
}

/* Portfolio Tile/Card */
.Card {
  width: 320px;
  height: 240px;
  background-color: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  padding: 5px 15px;
}

/* Portfolio Card Top */
.Portfolio-Card-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Portfolio Name -> hide overflow */
.Portfolio-Name {
  font-weight: bold;
  font-size: 18px;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Portfolio Card Description */
.Portfolio-Card-Description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 1.2rem;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

/* Portfolio Card  -> Projects */
.Portfolio-Card-Projects {
  background-color: #efefef;
  padding: 6px 10px;
  border-radius: 6px;
  margin: 8px 2px;
}

/* Footer  */
.Portfolio-Card-Footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  font-weight: 600;
}

.Card span {
  padding-left: 0.5rem;
  font-weight: 600;
}

/* --------------------------- */
/*  Dropdown -> Edit Icon      */
/* --------------------------- */

.Dropdown {
  position: relative;
  padding: 0.5rem 0;
}

.Dropdown-Content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
}

.Dropdown-Content p {
  padding: 12px 16px;
  margin: 0;
}

.Dropdown:hover img {
  cursor: pointer;
  transform: scale(1.2);
}

.Dropdown:hover .Dropdown-Content {
  display: block;
}

.Dropdown-Content p:nth-child(1):hover {
  background-color: #4285f4;
  color: #fff;
}

.Dropdown-Content p:nth-child(2) {
  color: #f44242;
  border-top: 1px solid #d9d9d9;
}

/* -------------------------------- */
/*  Portfolio Create/Edit/Delete    */
/* -------------------------------- */

/* Portfolio Viewer -> Input & Add Button */
.Portfolio-Viewer-Grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.Portfolio-Viewer-Left {
  grid-column: 1;
}

.Portfolio-Viewer-Right {
  grid-column: 2;
  padding-left: 1.5rem;
  cursor: pointer;
}

.Portfolio-Viewer-Loading {
  grid-column: 2;
  padding-left: 1.5rem;
  cursor: pointer;
  width: 50px;
}

/* Add Portfolio Viewers -> Scrollable Container */
.Scroll-Container {
  display: flex;
  flex-direction: column;
  max-height: 24vh;
  width: 53%;
  overflow-y: auto;
  margin: 10px 04px;
}

.Viewers {
  display: flex;
  flex-direction: row;
  gap: 0rem 1rem;
  cursor: pointer;
}

/* Portfolio Viewer Entry */
.Scroll-Container p {
  display: flex;
  align-items: center;
  background: #ebf7fa;
  padding: 5px 20px;
  width: 30vw;
  height: 35px;
  border-radius: 5px;
  margin: 5px;
}

/* Styling for Images */
.Create-Portfolio-Container img {
  width: 45px;
}

/* Hover for all images within the page */
.Create-Portfolio-Container img:hover {
  cursor: pointer;
  transform: scale(1.15);
}

/* ------------------------------------------- */
/*  Portfolio Projects Editor Pane             */
/* ------------------------------------------- */

.Portfolios-Projects-Editor-Container {
  display: grid;
  grid-template-columns: 1fr 0.25fr 1fr;
  grid-template-rows: 1fr 200px;
  gap: 15px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "Projects-Available-Header Projects-Selector-Header Projects-Added-Header"
    "Projects-Available-Body Projects-Selector-Body Projects-Added-Body";
  background-color: #F5F5F5;
  padding: 20px;
  width: 70%;
  border-radius: 5px;
}

.Projects-Available-Header {
  grid-area: Projects-Available-Header;
  padding: 0px 5px;
  font-weight: bold;
}

.Projects-Selector-Header {
  grid-area: Projects-Selector-Header;
}

.Projects-Added-Header {
  grid-area: Projects-Added-Header;
  padding: 0px 5px;
  font-weight: bold;
}

.Projects-Available-Body {
  grid-area: Projects-Available-Body;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

.Projects-Selector-Body {
  grid-area: Projects-Selector-Body;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.Projects-Added-Body {
  grid-area: Projects-Added-Body;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

/* Project Labels */

.Portfolio-Project-Label{
  padding: 5px;
  background-color: #EFEFEF;
  border-radius: 5px;
  margin: 0px 10px;
  cursor: pointer;
}

.Portfolio-Project-Label-Active{
  padding: 5px;
  background-color: #EBF7FA;
  border-radius: 5px;
  margin: 0px 10px;
  cursor: pointer;
}

/* --------------------------- */
/*  Scroll Bar                 */
/* --------------------------- */

/* width */
.Scroll-Container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.Scroll-Container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 10px;
  margin: 0.5rem;
}

/* Handle */
.Scroll-Container::-webkit-scrollbar-thumb {
  background: #4285f4;
  border-radius: 10px;
}

/* Handle on hover */
.Scroll-Container::-webkit-scrollbar-thumb:hover {
  background: rgb(15, 56, 133);
}

/* PROJECTS AVAILABLE PANE SCROLL BAR */

/* width */
.Projects-Available-Body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.Projects-Available-Body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 10px;
  margin: 1rem;
}

/* Handle */
.Projects-Available-Body::-webkit-scrollbar-thumb {
  background: #4285f4;
  border-radius: 10px;
}

/* Handle on hover */
.Projects-Available-Body::-webkit-scrollbar-thumb:hover {
  background: rgb(15, 56, 133);
}

/* PROJECTS ADDED PANE SCROLL BAR */

/* width */
.Projects-Added-Body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.Projects-Added-Body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 10px;
  margin: 1rem;
}

/* Handle */
.Projects-Added-Body::-webkit-scrollbar-thumb {
  background: #4285f4;
  border-radius: 10px;
}

/* Handle on hover */
.Projects-Added-Body::-webkit-scrollbar-thumb:hover {
  background: rgb(15, 56, 133);
}

/* ----------------------------------------- */
/*  Button Tooltips                          */
/* ----------------------------------------- */

.button-tooltip {
  position: relative;
}

.button-tooltip .tooltiptext {
  visibility: hidden;
  text-align: left;
  font-family: var(--body);
  width: fit-content;
  white-space: nowrap ;
  /* min-width: 300px; */
  background-color: var(--tooltip-background-colour, #343434);
  color: #FFFFFF;
  font-size: var(--fontsize-body);
  line-height: 1.4;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  z-index: 100;

  /* Position the tooltip */
  position: absolute;
}

.button-tooltip:hover .tooltiptext {
  visibility: visible;
}