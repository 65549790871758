:root {
  --stop-button: #fc3c78;
  --stop-button-hover: #f21258;
}

.FormResponse-Container {
  padding: 10% 15%;
  text-align: center;
  height: fit-content;
  background-color: rgb(253, 253, 253);
  overflow-anchor: none;
}

.FormResponse-Success {
  background-color: rgb(253, 253, 253);
  text-align: center;
  padding: 2%;
}

.FormResponse-Success-Title {
  font-size: var(--fontsize-subheader);
  font-weight: lighter;
  padding: 1% 0%;
  margin: 0%;
}

.formresponse-table-container {
  width: 60%;
  margin: 0% 20%;
  text-align: center;
}

table.formresponse-success {
  table-layout: fixed;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
}

table.formresponse-success td {
  padding: 15px;
  border: 1px solid rgb(226, 226, 226);
}
