.productfilterpane-container{
    display: grid;
    padding: 10px 25px;  
    background-color: white;
    border-radius: 5px;
    grid-template-columns: 1fr 120px;
    grid-template-areas: 
    'components refreshbutton';
}

.productfilterpane-components{
    grid-area: components;
    display: flex;
    width: fit-content;
    grid-gap: 2%;
    align-items: center;
}

.productfilterpane-refreshbutton {
    grid-area: refreshbutton;
    display: flex;
    justify-content: flex-end;
}

