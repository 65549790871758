.project-id-label {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    grid-column: 1;
    grid-row: 1;
    white-space: nowrap;
}

.project-id-textbox {
    font-family: var(--body);
    width: 400px;
    padding: 12px 20px;
    margin: 0px 0px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    font-size: var(--fontsize-body);
    grid-column: 2;
    grid-row: 1;
}

.project-id-textbox:hover {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.project-id-textbox:focus {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.project-id-error-label {
    font-family: var(--body);
    font-size: var(--fontsize-label);
    font-weight: bold;
    color: rgb(211, 26, 26);
    width: 400px;
    margin: 0px 0px 0px 0px;
    line-height: 1.6;
    grid-column: 2;
    grid-row: 2;
}

.find-owner-button{
    padding: 10px 15px;
    margin: 0px 5px;
    font-size: var(--fontsize-body);
    font-family: var(--body);
    border: none;
    box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
    background-color: var(--button-action-colour, #0C6CDE);
    color: white;
    border-radius: 3px;
    grid-column: 3;
    grid-row: 1;
    width: 150px;
    height: fit-content;
}

.find-owner-button:hover{
    background-color: var(--button-action-colour-hover, #00499F);
}

.find-owner-button:disabled {
    background-color: rgb(233, 233, 233);
    color: rgb(199, 198, 198);
    box-shadow: none;
    cursor: not-allowed;
}

.find-owner-edit-icon {
    word-wrap: break-word;
    font-size: var(--fontsize-body);
    text-align: center;
    border-radius: 2px;
    padding: 5px;
    min-width: 25px;
    min-height: 25px;
    cursor: pointer;
}
  
.find-owner-edit-icon:hover {
    background-color: #e9f1ff;
    padding: 5px;
}


.download-all-button{
    padding: 10px 15px;
    font-size: var(--fontsize-body);
    font-family: var(--body);
    border: none;
    box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
    background-color: var(--button-action-colour, #0C6CDE);
    color: white;
    border-radius: 3px;
    grid-column: 4;
    grid-row: 1;
    width: 150px;
    height: fit-content;
    text-decoration: none;
    text-align: center;
}

.download-all-button:hover{
    background-color: var(--button-action-colour-hover, #00499F);
}

.download-all-button:disabled {
    background-color: rgb(233, 233, 233);
    color: rgb(199, 198, 198);
    box-shadow: none;
    cursor: not-allowed;
}
