.shortinputfield {
    font-family: var(--body);
    width: 30%;
    padding: 12px 20px;
    margin: 0px 0px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    font-size: var(--fontsize-body);
}

.shortinputfield::placeholder {
    color: var(--inputfield-placeholder-color);
}

.mediuminputfield {
    font-family: var(--body);
    width: 50%;
    padding: 12px 20px;
    margin: 0px 0px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    font-size: var(--fontsize-body);
}

.mediuminputfield::placeholder {
    color: var(--inputfield-placeholder-color);
}

.longinputfield {
    font-family: var(--body);
    width: 100%;
    padding: 12px 20px;
    margin: 0px 0px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    font-size: var(--fontsize-body);
}

.longinputfield::placeholder {
    color: var(--inputfield-placeholder-color);
}