.question-container {
    padding: 20px 0px;
    display: flex;
    align-content: center;
}

.question-label {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    font-weight: bold;
}

.help-icon{
    margin-left: 5px;
    width: 1rem;
    height: 1rem;
}