/* ------------------------------ */
/*  Project Type (Cube) Container */
/* ------------------------------ */

.Project-Type-Container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 20px 0px 30px 0px;
}

.Project-Type-Icon{
    border: 2px solid #D9D9D9;
    border-radius: 10px;
    padding: 20px;
    width: 100px;
    height: 100px;
    justify-content: center;
    text-align: center;
}

.Project-Type-Icon img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.Project-Type-Icon:hover{
    border-color: #0C6CDE;
    cursor: pointer;
}

.Project-Icon-Selected{
    border: 2px solid #0C6CDE;
    border-radius: 10px;
    padding: 20px;
    width: 100px;
    height: 100px;
    justify-content: center;
    text-align: center;
}

.Project-Icon-Selected img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.body1{
    padding: 1px;
    font-weight: lighter;
    font-family: var(--body);
}

.body2{
    padding: 1px;
    font-weight: lighter;
    font-family: var(--body);
    padding-bottom: 30px;
}

.submit-button{
    background-color: var(--button-action-colour, #0C6CDE);
    border: none;
    color: white;
    padding: 15px 30px;
    margin: 40px 0px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: var(--fontsize-body);
    font-weight: bold;
    text-align: center;
}

.submit-button:hover{
    background-color: var(--button-action-colour-hover, #00499F);
}

.Project-Create-response-label {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    font-style: italic;
    margin: 0px 0px 0px 0px;
    line-height: 1.6;
}