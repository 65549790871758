.request-table-response-container {
    background-color: white;
    border-radius: 2px;
    padding: 15px;
    border-bottom: 1px solid rgb(208, 208, 208);
    border-right: 1px solid rgb(208, 208, 208);
    margin: 2% 0% 3% 0%;
    min-width: 685px;
    overflow: auto;
    text-align: center;
}

.request-table-response-text-container {
    background-color: white;
    border-radius: 2px;
    padding: 15px;
    /* border-bottom: 1px solid rgb(208, 208, 208);
    border-right: 1px solid rgb(208, 208, 208); */
    text-align: left;
    min-width: 685px;
    overflow: auto;
}
