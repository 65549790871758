/* ------------------------------------------------ */
/* Firewall base styling                            */
/* ------------------------------------------------ */

  
/* Styling for the active rules table heading and button cluster */
.Firewall-Active-Rules-Header {
    display: grid;
    grid-template-areas: "Firewall-Active-Rules-Header-Text Firewall-Active-Rules-Header-Buttons";
    grid-template-columns: 1fr 500px;
}

.Firewall-Active-Rules-Header-Text {
    grid-area: Firewall-Active-Rules-Header-Text;
    font-size: var(--fontsize-header);
}

.Firewall-Active-Rules-Header-Buttons {
    grid-area: Firewall-Active-Rules-Header-Buttons;
    padding: 0% 2%;
    align-self: center;
    display: flex;
    justify-content: space-around;
}

/* ------------------------------------------------ */
/* Firewall Interactive Table Styling               */
/* ------------------------------------------------ */

/* The expanded data row only */
.Firewall-InteractiveTable-Row-Expanded {
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
    background-color: #fdfdfd;
}

/* Data sections of table that you can click on to expand/collapse the row */
.Firewall-InteractiveTable-Data-Collapsible {
    padding: 5px 10px;
}

.Firewall-InteractiveTable-Data-Collapsible:hover {
    cursor: pointer;
}

/* ------------------------------------------------ */
/*  Interactive Table Expanded Section Styling      */
/* ------------------------------------------------ */

.Firewall-ExpandedRow {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-template-areas:
        "Firewall-ExpandedRow-Left Firewall-ExpandedRow-Right";
    padding: 0px calc(5% + 20px) 10px calc(5% + 20px);
    font-size: var(--fontsize-body);
}

/* Left column of data */
.Firewall-ExpandedRow-Left {
    grid-area: Firewall-ExpandedRow-Left;
    font-size: var(--fontsize-body);
}

/* Right column of data */
.Firewall-ExpandedRow-Right {
    grid-area: Firewall-ExpandedRow-Right;
    font-size: var(--fontsize-body);
    align-self: flex-start;
}

/* Label for individual fields in expanded row */
.Firewall-ExpandedRow-FieldLabel {
    color: #747575;
    margin: 20px 0px 0px 0px;
}

/* ------------------------------------------------ */
/*  Generic Firewall Forms                          */
/* ------------------------------------------------ */

.Firewall-Management-Pane {
    margin: 2% 0%;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    min-width: 685px;
    display: flex;
    flex-direction: column;
    line-height: 2.0;
    justify-content: center;
}

.Firewall-Management-Button-Pane {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}
.Firewall-Management-Button-Pane button {
    margin-right: 10px;
}

.Firewall-Add-Account-Button {
    padding: 5px;
    margin: 10px 0px;
    font-size: var(--fontsize-body);
    font-family: var(--body);
    border: none;
    box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
    background-color: var(--button-action-colour, #0c6cde);
    color: white;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: 60px;
}

.Firewall-Add-Account-Button:hover {
    transform: scale(1.03);
}
