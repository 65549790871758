/* ------------------------- */
/* Search Pane               */
/* ------------------------- */

.Knowledge-Search-Pane {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "Knowledge-Heading"
        "Knowledge-Search"
        "Knowledge-Popular-Topics";
    background-color: white;
    justify-items: center;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
}

.Knowledge-Heading {
    grid-area: Knowledge-Heading;
    font-weight: bold;
    font-size: var(--fontsize-header);
}


/* Search Bar Properties */

.Knowledge-Search {
    grid-area: Knowledge-Search;
    width: 100%;
    margin: 10px 0px 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Knowledge-Search-Input {
    width: 30%;
    padding: 15px 35px;
    border: 1px solid rgb(201, 201, 201);
    border-radius: 30px;
    transition-duration: 0.1s;
font-size: var(--fontsize-body);
    font-family: var(--body);
    background-color: rgba(255, 255, 255, 0.295);
}

.Knowledge-Search-Clear {
    margin: 0px 10px;
    cursor: pointer;
}

.Knowledge-Search-Input:hover {
    border: 1px solid rgb(165, 165, 165);
    transform: scale(1.005);
}

.Knowledge-Search-Input:focus {
    border: 1px solid rgb(165, 165, 165);
    transform: scale(1.005);
}

.Knowledge-Popular-Topics {
    grid-area: Knowledge-Popular-Topics;
    color: #666768
}

.Knowledge-Topic-Link {
    cursor: pointer;
}

/* ------------------------- */
/* Results Pane              */
/* ------------------------- */


.Knowledge-Results-Pane {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "Knowledge-Filter-Pane Knowledge-Article-Pane";
    margin: 2% 0%;
}

/* Filter by Category Pane */

.Knowledge-Filter-Pane {
    grid-area: Knowledge-Filter-Pane;
    background-color: #f7f7f7;
    padding: 20px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Category Button */

.Knowledge-Category-Button {
    text-align: left;
    font-family: var(--body);
    font-size: var(--fontsize-body);
    padding: 10px 25px;
    color: #444444;
    height: fit-content;
    border: none;
    margin-left: 5px;
    background-color: #f7f7f7;
    border-radius: 5px;
}

.Knowledge-Category-Button:hover {
    background-color:#e8ecec;
    cursor: pointer;
}

/* Selected Category Button */

.Knowledge-Category-Button-Selected {
    text-align: left;
    font-family: var(--body);
    font-size: var(--fontsize-body);
    padding: 12px 25px;
    background-color: #DEF6FC;
    height: fit-content;
    border: none;
    margin-left: 5px;
    border-left: 5px solid #18B4EA;
    border-radius: 5px;
}

.Knowledge-Clear-Filters{
    width: 20%;
    margin: 15px 10px;
    font-family: var(--body);
    font-size: var(--fontsize-label);
    padding: 5px;
    border: none;
    border-radius: 3px;
    background-color: #DEE6E9;
}

/* Search Results > Knowledge Articles */

.Knowledge-Article-Pane {
    grid-area: Knowledge-Article-Pane;
}
