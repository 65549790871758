.PageHeader-Container {
    background-color: #E2F0FE;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 20px;
}

.PageHeader-Header {
    font-size: var(--fontsize-header);
}

.PageHeader-Header img {    
    width: 25px;
    cursor: pointer;
    margin-left: 10px;
}

.PageHeader-Header img:hover {
    transform: scale(1.1);
}

.PageHeader-Body {
    margin-top: 20px;
    line-height: 1.7;

}

/* Default modal built into the PageHeader */

.PageHeader-Modal-Container {
    z-index: 2;
    position: absolute;
    width: 70vw;
    height: 70vh;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: 1%;
    border-radius: 5px;
    overflow: hidden;
}

.PageHeader-Modal-Header {
    display: flex;
    justify-content: space-between;
    font-size: var(--fontsize-header);
    margin-bottom: 2%;
}

.PageHeader-Modal-Header img {
    cursor: pointer;
    height: 30px;
}