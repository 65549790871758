.modal-background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-container {
  width: 70vw;
  max-height: 70vh;
  background: #ffffff;
}

.modal-header {
  padding: 10px;
  text-align: center;
}

.modal-content {
  border-top: 1px solid #c5c3c3;
  padding: 40px 70px;
  line-height: 1.7;
  overflow-y: scroll;
}

.modal-footer {
  border-top: 1px solid #c5c3c3;
  padding: 30px;
  text-align: center;
}

.nonpassport-accounts {
  color: var(--link-text-colour);
}

.continue-button {
  padding: 10px 30px;
  font-size: var(--fontsize-button);
  font-family: var(--body);
  border: none;
  box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
  background-color: var(--button-colour, #0c6cde);
  color: white;
  border-radius: 3px;
  margin: 0px 10px;
}

.continue-button:hover {
  background-color: var(--button-colour-hover, #00499f);
  cursor: pointer;
}

.cancel-button {
  padding: 10px 30px;
  margin: 0px 10px;
  font-size: var(--fontsize-button);
  font-family: var(--body);
  border: none;
  box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
  border: 1px solid var(--button-action-colour, #0c6cde);
  background-color: white;
  color: var(--button-action-colour, #0c6cde);
  border-radius: 3px;
  margin: 0px 10px;
}

.cancel-button:hover {
  background-color: rgb(225, 237, 255);
  cursor: pointer;
}

/* Pending requestType */
.modal-content-pending {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Success requestType */
.modal-content-success {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Error-Fatal requestType */
.modal-content-error-fatal {
  display: flex;
  justify-content: center;
  align-items: center;
}
