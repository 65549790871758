.product-parent-container{
    min-width: 60%;
    max-width: 100%;
    height: 100%;
}

.product-pane{
    padding: 15px 20px;
    border-radius: 5px;
    border-bottom: 1px solid rgb(208, 208, 208);
    border-right: 1px solid rgb(208, 208, 208);
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.form-interface-logo-img {
    display: grid;
    grid-column: 2;
    grid-row: 1;
    align-self: center;
    justify-self: center;
    height: 80%;
    width: 80%;
    min-height: 200px;
    min-width: 200px;
}

.form-message-container {
    grid-column: span 3;
    grid-row: 2;
    text-align: center;
    align-self: center;
    justify-self: center;
    width: 100%;
}
    

.form-button-container {
    grid-column: 2;
    grid-row: 3;
    align-self: center;
    justify-self: center;
}

.form-retry-button {
    width: 150px;
    height: 50px;
    padding: 10px 15px;
    margin: 0px 10px;
    font-size: var(--fontsize-body);
    font-family: var(--body);
    border: none;
    box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
    background-color: var(--button-action-colour, #0C6CDE);
    color: white;
    border-radius: 3px;
    cursor: pointer;
}

.form-retry-button:hover{
    background-color: var(--button-action-colour-hover,#00499F);
    color: white;
}