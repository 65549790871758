/* --------------------------- */
/*  Knowledge-Article-Link     */
/* --------------------------- */

.Knowledge-Article-Link {
    background-color: white;
    padding: 5px 20px 20px 20px;
    margin: 0px 10px 10px 10px;
    cursor: pointer;
    line-height: 1.6;
    border-radius: 5px;
}

.Knowledge-Article-Link:hover {
    background-color: #fcfcfc;
}

.Knowledge-Article-Title:hover {
    text-decoration: underline;
}

.Knowledge-Article-Tags {
    display: flex;
    flex-direction: row;
    gap: 0px 20px;
    align-items: center;
}

.Knowledge-Article-Label {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    padding: 5px 10px;
    line-height: 1.7;
    background-color: #DEF6FC;
    border-radius: 5px;
    color: rgb(48, 59, 83);
    text-transform: capitalize;
    width: fit-content;
    height: fit-content;
}

.Knowledge-Article-Date {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    color: #686363;
}

/* --------------------------- */
/*  Knowledge-Article-View     */
/* --------------------------- */


.Knowledge-Article-View {
    margin: 2% 0%;
}

.Knowledge-Article-Summary{
    padding: 10px 25px 15px 25px;
    background-color: white;
}

.Knowledge-Article-Back-Button{
    border: none;
    color: #0C6CDE;
    background-color: white;
    font-family: var(--body);
    font-size: var(--fontsize-button);
    width: fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: 3px;
    margin: 10px 0px;
}


.Knowledge-Article-Back-Button:hover{
    background-color: #DEF6FC;
}

.Knowledge-Article-Heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Knowledge-Article-PDF{
    width: 100%;
    height: 100vh;
}