.table-response-container-pending-user {
  background-color: white;
  border-radius: 2px;
  padding: 15px;
  border-bottom: 1px solid rgb(208, 208, 208);
  border-right: 1px solid rgb(208, 208, 208);
  margin: 2% 0% 3% 0%;
  min-width: 685px;
  overflow: auto;
  text-align: center;
}

.table-response-text-container-pending-user {
  background-color: white;
  border-radius: 2px;
  padding: 15px;
  /* border-bottom: 1px solid rgb(208, 208, 208);
  border-right: 1px solid rgb(208, 208, 208); */
  text-align: left;
  min-width: 685px;
  overflow: auto;
}

.table-response-button-pending-user {
  padding: 10px 15px;
  margin: 0px 10px;
  font-size: var(--fontsize-body);
  font-family: var(--body);
  border: none;
  box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
  background-color: var(--button-action-colour, #0c6cde);
  color: white;
  border-radius: 3px;
}

.table-response-button-pending-user:hover {
  background-color: var(--button-action-colour-hover, #00499f);
  color: white;
}