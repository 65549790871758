/* -------------------- */
/*  Project Dashboard   */
/* -------------------- */

.Dashboard-Container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
    grid-template-areas:
        "Dashboard-Col1 Dashboard-Col2 Dashboard-Col3";
    padding: 1%;
}

.Dashboard-Col1 {
    grid-area: Dashboard-Col1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Dashboard-Col2 {
    grid-area: Dashboard-Col2;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Dashboard-Col3 {
    grid-area: Dashboard-Col3;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* -------------------- */
/*  Dashboard Tile      */
/* -------------------- */

.Dashboard-Tile-Container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    border: 1px solid #BBBBBB;
    background-color: white;
    font-size: 20px;
    padding: 20px 0px 0px 0px;
}

.Dashboard-Tile-Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0px 20px;
}

.Dashboard-Tile-Body {
    font-size: var(--fontsize-body);
    line-height: 1.6;
    padding: 0px 20px 0px 65px;
}

/* Project Information Tile */

.Dashboard-Tile-Project-Body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Dashboard-Tile-Project-Fields {
    color: #5E5E5E;
    margin: 0px;
}

/* Security Tile */

.Dashboard-Tile-Security-Tile {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-right: 25px;
}

.Dashboard-Tile-Security-Tile p{
    margin: 5px 0px;
    color: #5E5E5E;
}

.Dashboard-Tile-Security-Finding-Summary {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.Dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

/* Portfolio Information Tile */

.Dashboard-Tile-Portfolio-Information-Tile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 20px;
}

.Dashboard-Tile-Portfolio-Description {
    line-height: 1.6;
    overflow: hidden;
    display: -webkit-box; 
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    height: 1.5rem; /* fallback */
    margin: 0px;
    color: #5E5E5E;
}

.Dashboard-Tile-Portfolio-Projects{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 5px;
}

.Dashboard-Tile-Portfolio-Projects label{
    background-color: #efefef;
    padding: 5px 10px;
    border-radius: 6px;
    margin: 0px;
}

.Dashboard-Tile-Portfolio-ProjectTotal{
    color: #3c3c3c;
    margin: 5px 0px;
}

/* Sustainability Tile */

.Dashboard-Tile-Sustainability-Tile {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Dashboard-Tile-Sustainability-Tile div{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 0px;
    color: #5E5E5E;
}

.Dashboard-Tile-Sustainability-Tile p{
    margin: 5px 0px;
    color: #5E5E5E;
}

/* Billing Tile */

.Dashboard-Tile-Billing {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Dashboard-Tile-Billing p{
    margin: 0px;
    color: #5E5E5E;
}

/* Dashboard Tile Footer */

.Dashboard-Tile-Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    font-size: var(--fontsize-body);
    padding: 15px 20px;
    border-top: 0.5px solid #BBBBBB;
    color: #4E4E4E;
    cursor: pointer;
}

.Dashboard-Tile-Footer:hover {
    text-decoration: underline;
    color: #0C6CDE;
}

/* --------------------------- */
/*  Welcome Page               */
/* --------------------------- */

.WelcomePage-Container {  
  display: grid;
  grid-template-rows: 0.4fr 0.5fr 0.8fr;
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-areas:
  'WelcomeHeader WelcomeHeader WelcomeHeader'
  'WelcomeBody WelcomeBody WelcomeBody'
  'QuickAccess QuickAccess QuickAccess';
  
  grid-auto-flow: row;
  background-color: #FFFFFF;
  color: #4d4d4d;
  padding: 50px 80px;
  border-radius: 5px;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.WelcomeHeader { 
  grid-area: WelcomeHeader; 
  color: #282E36;
  display: flex;
  justify-content: start;
}

.WelcomeBody { 
  grid-area: WelcomeBody; 
  align-self: start; 
}

.QuickAccess { 
  grid-area: QuickAccess; 
  align-self: start; 
  color: #282E36;
}

li{
    margin-top: 12px;
}

/* Buttons */
.QuickAccess button {
    background-color: #FFFFFF;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    padding: 5px 5px;
    margin: 0px 25px;
    margin-left: 0px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    width: 200px;
    height: 60px;
    text-align: left;
    font-family: Circular-body;
    color: #282E36;   
}

.QuickAccess button:hover{
    transform:scale(1.01); 
}

.WelcomeBody button {
    color: #0000EE;
    background-color: #FFFFFF;
    border: none;
    font-size: 16px; 
    text-decoration: underline;
    font-family: Circular-body;
    cursor: pointer;
    padding: 0px 0px;
}


  
