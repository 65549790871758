.Attach-To-VPC-Pane {
    padding: 10px 30px;
    border-bottom: 1px solid rgb(208, 208, 208);
    border-right: 1px solid rgb(208, 208, 208);
    background-color: white;
    border-radius: 2px;
}

/* The checkbox-container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid rgb(128, 128, 128);
    border-radius: 2px;
    margin-top: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
    background-color: rgb(235, 235, 235);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
    background-color: #0C6CDE;
    border: none;
    border: 1.5px solid #0C6CDE;

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

table.attachvpc-success{
    table-layout: fixed;
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

table.attachvpc-success td{
    padding: 10px;
    border: 1px solid rgb(226, 226, 226);
}

.modify-button {
    padding: 10px 15px;
    margin: 0px 10px;
    font-size: var(--fontsize-body);
    font-family: var(--body);
    border: none;
    box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
    background-color: var(--button-action-colour, #0C6CDE);
    color: white;
    border-radius: 3px;
    cursor: pointer;
}

