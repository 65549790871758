/* Project Page Container */
.Projects-Container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

/* Project Tiles Container */
.Projects-Tile-Section {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

/* Add Project Card */
.Project-Card-Add {
  width: 320px;
  height: 180px;
  background-color: #fff;
  border: 2px dashed #d9d9d9;
  border-radius: 10px;
  display: grid;
  place-items: center;
  padding: 10px;
}

.Project-Card-Add:hover {
  cursor: pointer;
  transform: scale(1.02);
  border-color: #0C6CDE;
}

/* Project Card Container */
.Project-Card-Container {
  display: grid;
  grid-template-rows: 50px 40px 90px;
  grid-template-areas:
    "card-top"
    "card-middle"
    "card-bottom";
  width: 320px;
  height: 180px;
  background-color: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
}

.Project-Card-Top {
  grid-area: card-top;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0px 0px 0px 10px;
}

.Project-Card-Icons {
  display: flex;
}

.Project-Card-Console-Icon {
  cursor: pointer;
  height: 30px;
  padding: 10px 10px 10px 10px;
  margin: 0px 10px 0px 0px
}

.Project-Card-Edit-Icon {
  cursor: pointer;
  height: 30px;
  padding: 10px;
}

.Project-Card-Edit-Icon:hover .Project-Card-Menu {
  opacity: 1;
  pointer-events: auto;
}

.Project-Card-Middle {
  grid-area: card-middle;
  font-weight: bold;
  font-size: 17px;
  padding: 0px 10px 0px 10px;
}

.Project-Card-Bottom {
  grid-area: card-bottom;
  padding: 0px 10px 10px 10px;
}

/* Dropdown Menu */
.Project-Card-Menu {
  opacity: 0;
  grid-area: card-middle;
  background-color: white;
  color: #515151;
  z-index: 10;
  position: absolute;
  margin-left: -200px;
  width: 230px;
  height: fit-content;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  align-self: self-start;
  pointer-events: none;
}

.Project-Card-Menu div {
  padding: 10px;
}

.Project-Card-Menu div:first-child {
  padding-top: 10px;
}

.Project-Card-Menu-Delete{
  padding-top: 10px;
  color: red;
  border-top: 1px solid #D9D9D9;
}

.Project-Card-Menu div:hover {
  background-color: #0C6CDE;
  color: white;
}