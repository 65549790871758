/* Refer to App.css for Sidebar CSS class */


.SidebarLinks a{
    display: block;
    text-decoration: none;
}

.Sidebar-Pending {
    text-align: center;
}

.Support-Button {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 20px;
    transition: transform .2s;
}

.Support-Button:hover {
    transform: scale(1.1);
}

/* change the first value in calc to adjust for navbar items */
@media screen and (max-height: 750px) {
    .Support-Button {
        display: none !important;
    }
}