:root {
    --label-text: white;
}

.success-label{
    border-radius: 20px;
    background-color: var(--ll-green, #06B448);
    color: var(--label-text, white);
    padding: 6px 15px;
    font-weight: normal;
    font-size: var(--fontsize-label);
    width: 100px;
}

.pending-label{
    border-radius: 20px;
    background-color: var(--ll-yellow, #F1AD29);
    font-size: var(--fontsize-label);
    color: #9E9318;
    padding: 6px 15px;
    font-weight: normal;
    width: 100px;
}

.rejected-label{
    border-radius: 20px;
    background-color: var(--ll-red, #E90707);
    font-size: var(--fontsize-label);
    color: var(--label-text, white);
    padding: 6px 15px;
    font-weight: normal;
    width: 100px;
}

.error-label{
    border-radius: 20px;
    background-color: var(--ll-red, #E90707);
    font-size: var(--fontsize-label);
    color: var(--label-text, white);
    padding: 6px 15px;
    font-weight: normal;
    width: 100px;
}