.inline-field-label{
    font-family: var(--body);
    font-size: var(--fontsize-body);
}

.shortinputfield {
    font-family: var(--body);
    width: 30%;
    padding: 12px 20px;
    margin: 0px 0px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: black;
    font-size: var(--fontsize-body);
}

.shortinputfield:hover {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.shortinputfield:focus {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.mediuminputfield {
    font-family: var(--body);
    width: 50%;
    padding: 12px 20px;
    margin: 0px 0px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: black;
    font-size: var(--fontsize-body);
}

.mediuminputfield:hover {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.mediuminputfield:focus {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.longinputfield {
    font-family: var(--body);
    width: 100%;
    padding: 12px 20px;
    margin: 0px 0px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: black;
    font-size: var(--fontsize-body);
}

.longinputfield:hover {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}

.longinputfield:focus {
    outline: none;
    border: 1px solid #0C6CDE;
    border-radius: 4px;
}