.tag-label-container {
    margin: 20px 0px;
}

.tag-label {
    font-size: var(--fontsize-body);
    font-weight: lighter;
    background-color: rgb(215, 246, 255);
    padding: 7px 18px;
    border-radius: 15px;
    width: fit-content;
    word-wrap: break-word;
}

.plain-label-container {
    margin: 15px 0px;
}

.plain-label{
    font-size: var(--fontsize-body);
    font-weight: normal;
    border-radius: 15px;
    width: fit-content;
    word-wrap: break-word;
}