/* ============================================== */
/*  Project Ownership Transfer Page Container     */
/* ============================================== */

.Project-Owner-Transfership-Container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* ============================================== */
/* Shared Classes */
/* ============================================== */

.OwnershipTransferCreate-Form-Container{
    background-color: white;
    padding: 2%;
}
.OwnershipTransfer-Button-Container{
    display: flex;
    justify-content: center;
}
.OwnershipTransfer-Button-Container button {
    margin: 2%;
}