/* Modal Image */
.modal-lucky-image-gethelp{
    grid-area: modal-lucky-image-gethelp;
    max-width: 25%;
    max-height: 25%;
    align-self: center;
    transform: rotate(0deg);
}

.modal-lucky-image-gethelp:hover{
    transition: 0.2s;
    transform: scale(1.3) rotate(5deg);
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}



/* Parent container and contents*/
.parent-container-gethelp{
    margin-top: 1%;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 1%;
    padding: 1% 0%;
    border-radius: 10px;
    background-color: inherit;
}


.content-container-gethelp{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap:2vmax;
    row-gap: 2vmax;
    background-color: inherit;
    max-height: max-content;
}