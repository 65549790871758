/* Billing Filter Pane */
.Billing-Filter-Pane{
    background-color: white;
    padding: 2%;
    font-size: var(--fontsize-subheader);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
}

.Billing-Filter-Pane span{
    margin-right: 10px;
}

.Billing-Invoices-Pane{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.Billing-Table-Header-Row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

.Billing-Table-Header-Row img{
    margin: 0px 30px;
}

/* Invoice Component */
.Invoice-Container {
    padding: 1%;
}

.invoice-subheading {
    font-weight: normal;
    font-family: var(--subheader);
    font-size: var(--fontsize-subheader);
    padding-bottom: 10px;
}

.invoice-body{
    line-height: 1.8;
    font-size: var(--fontsize-body);
}

.invoice-banner {
    text-align: center;
    font-weight: bolder;
    font-family: var(--bold);
    font-size: var(--fontsize-header);
    width: 100%;
}

.invoice-invoiceproperties table tr th {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    font-weight: normal;
    text-align: left;
    padding-right: 90px;
    padding: 5px;
}

.invoice-tabletitle {
    background-color: rgba(115, 152, 255, 0.267);
    margin-top: 5%;
    padding: 10px;
}

.invoice-childtable table tr th {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    width: 100%;
    padding: 5px;
    font-weight: normal;
    text-align: left;
}

.invoice-totalstable table tr th {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    font-weight: normal;
    text-align: left;
    width: 100%;
    padding-top: 10px;
    border-top: 3px solid #30373F;
}

.invoice-substitle {
    background-color: rgba(6, 18, 49, 0.171);
    margin-top: 5%;
    padding: 10px;
    font-size: var(--fontsize-body);
    font-weight: bold;
    font-family: var(--body);
}


.invoice-invoicetotalstable table tr th {
    font-family: var(--body);
    font-size: var(--fontsize-body);
    font-weight: normal;
    text-align: left;
    width: 100%;
    padding-top: 10px;
}

.invoice-creditremaingtable table tr th {
    font-family: var(--body);
    font-size: var(--fontsize-subheader);
    font-weight: normal;
    text-align: left;
    width: 100%;
    padding-top: 10px;
}

.invoice-creditremaingtable table td {
    font-family: var(--body);
    font-size: var(--fontsize-subheader);
    font-weight: normal;
    text-align: right;
    width: 100%;
    padding-top: 10px;
}

.invoice-note{
    font-family: var(--body);
    font-size: var(--fontsize-body);
    font-weight: normal;
    text-align: left;
    width: 100%;
    padding-top: 10px;
    outline: #30373F;
}