.product-parent-container{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    min-width: 60%;
    max-width: 100%;
    height: 100%;
}

.attach-to-vpc-button{
    padding: 10px 15px;
    margin: 0px 10px;
    font-size: var(--fontsize-button);
    font-family: var(--body);
    border: none;
    box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
    background-color: var(--button-action-colour, #0C6CDE);
    color: white;
    border-radius: 3px;
    grid-column: 2;
    grid-row: 1;
    width: 200px;
    height: fit-content;
}

.attach-to-vpc-button:hover{
    background-color: var(--button-action-colour-hover,#00499F);
    color: white;
}

.attach-to-vpc-button:disabled{
    background-color: rgb(233, 233, 233);
    color: rgb(199, 198, 198);
    box-shadow: none;
    cursor: not-allowed;
}

.manage-vpc-access-button{
    padding: 10px 15px;
    margin: 0px 10px;
    font-size: var(--fontsize-body);
    font-family: var(--body);
    border: none;
    box-shadow: 0px 5px 5px -4px rgb(204, 204, 204);
    background-color: var(--button-action-colour, #0C6CDE);
    color: white;
    border-radius: 3px;
    grid-column: 2;
    grid-row: 1;
    width: 200px;
    height: fit-content;
}

.manage-vpc-access-button:hover{
    background-color: var(--button-action-colour-hover,#00499F);
    color: white;
}

.manage-vpc-access-button:disabled{
    background-color: rgb(233, 233, 233);
    color: rgb(199, 198, 198);
    box-shadow: none;
    cursor: not-allowed;
}

/* ========================== */
/*  Warning Label             */
/* ========================== */

.svpc-warning-container{
    display: grid;
    grid-template-columns: 0.03fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "warning-icon warning-label";
    border-radius: 5px;
    /* background-color: #FFF7CE; */
    min-width: 200px;
    overflow: auto;
    margin: 5px 0px;
}   

.svpc-warning-icon{
    grid-area: warning-icon;
    max-width: 25px;
    max-height: 25px;
    align-self: center;
    justify-self: left;
}

.svpc-warning-label{
    grid-area: warning-label;
    align-self: center;
}